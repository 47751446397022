
.card_container{
    max-width: 410px;
    flex: 0 0 30%;
    background-color: white;
    display: flex;
        margin: 10px 20px;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
}
.title_container{
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
.dots{
    margin: 5px;
}
.dot{
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.63);
    width: 3px;
    height: 3px;
    margin: 3px;
    display: block;
}
.dots_container{
    cursor: pointer;
    padding: 0 5px;
}
.popover_container{
    position: absolute;
    background-color: white;
    border-radius: 10px;
    top: 0;
    right: 0;
    z-index: 10;
    box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
}
.popover_item{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
    padding: 5px;
    border-radius: 5px;
    padding-left: 10px;
}
.popover_item:hover{
    background-color: #e5e5e5;
}
.popover_item_delete{
    color: red
}
.popover_item_icon{
    margin-right: 10px;
}
.right_side{
    flex-direction: column;

    overflow: hidden;
    margin: 10px;
}
.first_line_card{
    word-break: break-word;
    font-weight: 600;
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
}
.hr{
    border-top: 2px solid #A4A4A4;
    margin-bottom: 1rem;
}
.hr_active{
    border-top: 2px solid rgb(30, 211, 66);
    margin-bottom: 1rem;
}
.card_container:hover .hr{
    transition: 0.5s ease;
    border-top: 2px solid rgb(30, 211, 66);
}
.place {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.placeText{
    cursor: pointer;
}
.placeText:hover{
    opacity: 0.7;
}

.textOverflow {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.modal_container{
    padding: 20px 40px ;
    min-width: 700px;
}
.modal_item_container{
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
}
.p{
    margin-bottom: 0;
}
.text_input_container{
    margin-bottom: 10px;
        width: 80%;
}
.text_input_container_date{
    width: 60%;
    margin-bottom: 10px;
}
.text_input_container_time{
    width: 100%;
    margin-bottom: 10px;
}
.date_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input{
    border-radius: 5px!important;
    border: 1px solid #A4A4A4!important;
    padding: 5px!important;
    width: 100%;
    outline: none;

}
.input_focus{
    border-radius: 5px!important;
    border: 1px solid #11B64B!important;
    padding: 5px!important;
    width: 100%;
    outline: none;
}
.addInput{
    background: transparent;
    border: 1px solid #11B64B;
    padding: 7px 10px;
    border-radius: 7px;
    color: #11B64B;
    margin-top: 5px;
}
.addInput:disabled{
    border: 1px solid grey;
    color: grey;
}

.w80{
    width: 80%;
}
.btnDeleteInput{
    font-size: 21px;
    margin-left: 5px;
    background: transparent;
}
.btnEdit{
    padding: 5px 15px;
    min-width: 80px;
    min-height: 35px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #11B64B;
    color: #11B64B;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    /*margin-right: 20px;*/
}
.btnDelete{
    min-width: 80px;
    padding: 5px 15px;
    min-height: 35px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #FF0000;
    color: #FF0000;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
}
.View_btn_pos{
    display: flex;
    justify-content: space-evenly;
    margin: 15px 0;
}
.btnEdit:hover{
    background: #11B64B;
    border: 1px solid #FFFFFF;
    color: white;
}

.btnDelete:hover{
    background: #FF0000;
    border: 1px solid #FFFFFF;
    color: white;
}
.description {
    word-break:break-word;
    /*display: -webkit-box;*/
    /*-webkit-line-clamp: 2;*/
    /*-webkit-box-orient: vertical;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
}
@media only screen and (max-width: 1400px) {
    .card_container {
        flex: 0 0 40%;
    }

}
@media (min-width: 635px) and (max-width: 991px){
    .modal_container{
        padding: 20px 40px ;
        min-width: 500px;
    }
}
@media (min-width: 635px) and (max-width: 991px){
    .modal_container{
        padding: 20px 40px ;
        min-width: 680px;
    }
}

@media (max-width: 634px){
    .modal_container{
        padding: 20px 40px ;
        min-width: 200px;
    }

}
@media (max-width: 576px){
    .modal_container{
        padding: 10px 30px;
        min-width: 400px;
    }
    .modal_item_container{
        display: block;
    }
    .date_container{
        display: block;
    }
}
@media (max-width: 576px){
    .card_container {
        margin: 10px 30px;
    }
    .text_input_container_date{
        width: 100%;
    }
    .text_input_container{
        width: 100%;
    }
}
@media (max-width: 479px){
    .card_container {
        margin: 20px 0 !important;
    }
    .modal_container{
        max-width: 300px;
        min-width: 250px;
    }
    .text_input_container_date{
        width: 100%;
    }
    .text_input_container{
        width: 100%;
    }
    .modal_container{
        padding: 10px 15px!important;
    }
    .modal_item_container .w80{
        width: 100%!important;
    }
}
