
.input{
    border-radius: 15px!important;
    border: 1px solid #A4A4A4!important;
    padding: 5px 10px!important;
    width: 100%;
    outline: none;
    margin-top: 5px!important;
}
.input::placeholder{
    color: #c5c4c4;
}
.input_focus{
    box-shadow: none!important;
    margin-top: 5px!important;
    border-radius: 15px!important;
    border: 1px solid #11B64B!important;
    padding:5px 10px!important;
    width: 100%;
    outline: none;
}
.error {
    box-shadow: none!important;
    margin-top: 5px!important;

    border-radius: 15px!important;
    border: 1px solid red!important;
    padding:5px 10px!important;
    width: 100%;
    outline: none;
}

.error_text {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}