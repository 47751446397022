body, html, * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    font-family: "Albert Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
}

body {
    overflow-x: hidden;
    background-color: #f0f0f0 !important;
    display: flex;
    flex-direction: column;
    height: 100%;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 16px;
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
}

footer {
    margin-top: auto;
}

.header_between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
}

a {
    text-decoration: none !important;
}

button {
    border: none;
}

*, ::after, ::before {
    box-sizing: inherit;
}

.modal-header {
    justify-content: center !important;
}

.modal-footer {
    justify-content: space-evenly !important;
}

.modal-footer button {
    background-color: transparent;
}

.modal-body {
    text-align: center;
}

.modal-content {
    width: auto !important;
}

input {
    height: 38px !important;
    border-radius: 15px !important;

}

input:focus {
    border-color: #11B64B !important;
}

.custom_input_profile {
    box-shadow: none!important;
    outline: none!important;
    border: 1px solid #A4A4A4 !important;
}

.modal-dialog-centered {
    justify-content: center !important;
}

.Buttons {
    padding: 5px 15px;
    border-radius: 10px;
}

.btnEdit {
    min-width: 90px;
    background-color: transparent;
    border: 1px solid #11B64B;
    color: #11B64B;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    margin-right: 10px;
}

.btn_logout {
    border: 1px solid red;
    border-radius: 10px;
    cursor: pointer;

}
.custom-btn{
    padding:5px 15px;
}
.btn_logout:hover {
    background-color: red;
    color: white !important;

}

.btnEdit:hover {
    background: #11B64B;
    border: 1px solid #FFFFFF;
    color: white;
}

.btnEdit:hover .green_icons {
    stroke: white;
}

.CreateReviewBtn:hover {
    background: #11B64B;
    border: 1px solid #FFFFFF;
    color: white;
}

.CreateInspectionBtn:hover {
    background: #0923f1;
    border: 1px solid #FFFFFF;
    color: white;
}

.CreateInspectionBtn {
    min-width: 80px;
    min-height: 35px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #0923f1;
    color: #0923f1;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
}

.btnEditColor {
    color: #ff9206;
    border: 1px solid #ff9206;
}

.btnEditColor:hover {
    background-color: #ff9206;
}
.ShareBtn:hover .yellow_icons{
    fill:white;
    stroke:white
}
.ShareBtn:hover{
    background-color: #28c3ff;
    color:white
}
.copy-container{
    color:grey
}
.CreateReviewBtn:hover .green_icons {
    fill: white;
}

.CreateInspectionBtn:hover .green_icons {
    stroke: white;
}

.form-select {
    min-width: 100px;
    border: 1px solid #A4A4A4 !important;
}

.btnCancel {
    border: 1px solid #A4A4A4;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    color: #838383;
}

.btnCancel:hover {
    color: white;
    background: #A4A4A4;
    border: 1px solid #A4A4A4;
    border-radius: 10px;
}

.btnDelete {
    min-width: 90px;
    background-color: transparent;
    border: 1px solid #FF0000;
    color: #FF0000;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
}

.btnDelete:hover {
    background: #FF0000;
    border: 1px solid #FFFFFF;
    color: white;
}

.btnDelete:hover .red_icons {
    fill: white;
}

.activeInput {
    border: 1px solid #58b358 !important;
}

.hr {
    border-bottom: 1px solid #d0d0d0;
    margin-top: 40px;
}

.custom-margin-style input::placeholder {
    color: #A4A4A4;
}

.btnCreateCard {
    display: flex;
    padding: 10px;
    background-color: #01ce61;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    align-items: center;
    position: fixed;
    right: 30px;
    bottom: 65px;
}

.btnCreateCard:hover {
    opacity: 0.7;
    transition: opacity 0.2s;
}

.modal-all-photos {
    top: 30px;
    bottom: 30px;
    max-height: 100vh;
    position: fixed;
    flex-direction: column;
    overflow: auto;
}


.modal-all-photos::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

.modal-all-photos::-webkit-scrollbar-track {
    background-color: #020202;
}

.modal-all-photos::-webkit-scrollbar-thumb {
    background-color: #888;
}

.modal-all-photos::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.custom-modal {
    background-color: rgba(0, 0, 0, 1);
}

.custom-modal-right-side {
    padding-left: 200px;
    width: 100%;
    height: 900px;
    overflow: hidden;
    position: relative;
}

.custom-modal-right-side img {
    width: 78%;
    height: 90%;
    object-fit: contain;
}

.custom-modal-left-side-block {
    flex: 0 0 150px;
    height: 150px;
    object-fit: cover;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    padding: 1px;
}
.general_info_width{
    width: 75%;
}
.custom-modal-left-side-block:hover {
    padding: 1px;
    opacity: 100%;
    border: 1px solid white;
}
.close_modal_alert{
    position: absolute;
    right: -1px;
    z-index: 10000000;
    background: rgba(0, 0, 0, 0.7)!important;
    padding: 0 15px;
    color: white;
    font-size: 30px;
    cursor: pointer;
    top: -1px;
}
.container_noData {
    font-weight: 450;
    padding: 25px 45px 30px;
    margin-top: 20px;
    background: white;
    border-radius: 16px;
    box-shadow: 4px 4px 19px #4d4848;
}

.custom_add_card_btn {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2AA06E;
    width: fit-content;
    color: white;
    border-radius: 6px;
    font-size: 19px;
    line-height: normal;
}

.footer_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    justify-items: center;
    border-top: 1px solid #ddd;
    padding-top: 40px;
}

.mainImageModal {
    padding: 20px;
    height: 100%;
    max-width: 1300px;
}

.opacity_arrow:hover {
    opacity: 0.7;
}
.companyImg .noPhoto{

    opacity: 0.4;
}
.general_info .title{
    font-weight: bold;
}
.general_info .row_container{
    min-width: 400px;
}
@media (max-width: 1475px) {
    .mainImageModal {
        height: 85% !important;
    }
}

@media (max-width: 1300px) {
    .mainImageModal {
        height: 70% !important;
    }
}

@media (max-width: 1192px) {
    .mainImageModal {
        height: 65% !important;
    }
    .employee_list_width{
        width: 60% !important;
    }
}

@media (max-width: 1110px) {
    .mainImageModal {
        height: 60% !important;
    }
}

@media (max-width: 992px) {
    .header_between{
        padding: 0 15px;
    }
    .custom-modal_container {
        flex-direction: column;
    }

    .CreateInspectionBtn {
        margin-bottom: 10px;
    }
    .ShareBtn{
        margin-bottom: 10px;
    }
    .modal-all-photos {
        position: fixed !important;
        flex-direction: row;
        bottom: 0;
        left: 30px;
        right: 30px;
        top: auto;
    }

    .custom-modal-right-side {
        padding-left: 0;
    }

    .mainImageModal {
        height: 50% !important;
    }

    .btn_slide_bottom {
        right: 0;
        width: 25px !important;
        height: 150px !important;
        background-image: url("assets/img/arrow_right.png") !important;
        border-radius: 0 10px 10px 0 !important;
        bottom: 5px !important;
    }

    .btn_slide_top {
        top: auto !important;
        bottom: 5px !important;
        width: 25px !important;
        height: 150px !important;
        background-image: url("assets/img/arrpw_left.png") !important;
        border-radius: 10px 0 0 10px !important;
    }
    .extensionPhoto{
        width: 100%!important;
    }
    .custom-video_container{
        width: 100%!important;
    }
}

@media (max-width: 768px) {
    .company_shared_text.name{
        font-size: 24px!important;
    }
    .company_shared_text{
        font-size: 16px;
    }
    .appstore-original-custom {
        margin-top: 0 !important;
    }

    .googleplay-original-custom {
        margin-top: 0 !important;
    }
    .employee_list_width{
        width: 100% !important;
    }
    .general_info_width{
        width: 100%;
    }
}

.selectedPhoto {
    border: 3px solid rgba(220, 115, 6, 0.99);
    opacity: 100%;
}

.selectedPhoto:hover {
    border: 3px solid rgba(220, 115, 6, 0.99);
    opacity: 100%;

}


@media (max-width: 599px) {
    .employee_list_component.row {
        flex-direction: column;
        align-items: flex-start;
        position: relative!important;
    }

    .employee_list_component .col-5,
    .employee_list_component .col-6 {
        width: 80% !important;
    }

    .employee_list_component .col-1 {
        width: 50px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 10px;
    }
}


@media (max-width: 599px) {
    .footer_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer_container p{
        margin-bottom: 0!important;
    }
    .footer_container h5{
        margin-bottom: 7px!important;
    }
    .store {
        display: flex !important;
        justify-content: center !important;
    }
    .custom-input-margin{
        margin-right: 0!important;
    }
    .custom-flex-column{
        flex-direction: column;
    }
    .custom-input-padding-left{
        padding-left: 0!important;
    }
    .custom-input-padding-right{
        padding-right: 0!important;
    }
    .custom-margin-footer{
        display: flex;
        flex-direction: column;
    }
    .h5-sm{
        text-align: center;
    }
    .mg-top-main{
        margin-top: 100px;
    }
    .foo-links{
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        flex-direction: column!important;
    }
    .info_container .general_info{
        flex-direction: column;
    }
    .info_container .companyImg {
        margin-left: auto;
        margin-right: auto!important;
        margin-bottom: 15px;
        width: 100%;
    }
    .info_container{
        padding: 10px 20px
    }

}
@media (max-width: 665px) {
    .mainImageModal {
        height: 40% !important;
    }
}

/*@media (max-width: 1256px) {*/
/*    .li_navbar{*/
/*        font-size: ;*/
/*    }*/
/*}*/
@media (min-width: 600px) and (max-width: 1012px) {
    .footer_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1400px) {
    .googleplay-original-custom {
        margin-top: 10px;
    }
}
@media (max-width: 400px){
    .cardContainer {
        padding: 0 20px!important;
    }
}
.btn_slide_bottom {
    position: fixed;
    bottom: 0;
    z-index: 30;
    width: 200px;
    border-radius: 0 0 10px 10px;
    background-color: rgba(255, 255, 255, .16);
    color: white;
    font-weight: bolder;
    background-image: url("assets/img/arrow_top.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 25px;
}

.btn_slide_top {
    position: fixed;
    top: 0;
    z-index: 30;
    width: 200px;
    border-radius: 10px 10px 0 0;
    background-color: rgba(255, 255, 255, .16);
    color: white;
    font-weight: bolder;
    background-image: url("assets/img/arrow_bottom.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 25px;
}
.ShareBtn{
    min-width: 80px;
    min-height: 35px;
    margin-right: 10px;
    border-radius: 10px;

    background-color: transparent;
    border: 1px solid #28c3ff;
    color: #28c3ff;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
}
.CreateReviewBtn {
    min-width: 80px;
    min-height: 35px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #11B64B;
    color: #11B64B;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
}

@media only screen and (max-width: 992px) {
    .custom_column_company{
        flex-direction: column;
        align-items: center;
    }
    .company_logo_container{
        margin-left: 20px!important;
    }
    .CreateReviewBtn {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .btnEdit {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .appstore-original-custom {
        margin-top: 10px;
    }

    .home_btn_navbar {
        padding: 12px;
    }
    .extension_left{
        flex-direction: column;
        align-items: center;
    }
    .extensionBlock{
        width: 100%!important;
        margin-bottom: 10px;
    }
    .arrowRightOnlineInspections{
        width: 50px!important;
    }
    .arrowLeftOnlineInspections{
        width: 50px!important;
    }
    .extension_right{
        flex-direction: column-reverse;
        align-items: center;
    }
    .extension_left2_block_photo{
        flex-direction: column;
        align-items: center!important;
    }
    .arrowRightOnlineInspections2{
        width: 50px!important;
    }
    .extension_right2{
        align-items: center!important;
        justify-content: center!important;
    }
    .extensionBlock{
        width: 100% !important;
    }
    .custom-video-iframe{
        width: 500px!important;
    }
}

.extension_right2{
    justify-content: flex-end;
}
.extension_left2_block_photo{
    align-items: flex-end;
}
.profile_container {
    border-radius: 20px;
}
.company_setting_title{
    font-size: 26px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 12px;
    color: #6e6e6e;
}
.info_container .companyImg{
    max-width: 400px;
    margin-right: 25px;
}
.info_container img{
    max-width: 100%;
    height: auto;
}
.info_container .general_info{
    width: 100%;
    display: flex;
    justify-content: center;
}
.button_logout {
    padding: 5px 10px;
    color: white;
    font-size: 18px;
    border-radius: 5px;
}

.share_input {
    background-color: transparent;
    border-radius: 15px;
    border: 1px solid #11b64b;
    min-width: 250px;
    margin-top: 10px;
    cursor: pointer;
    padding: 8px;
    max-width: 350px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.yellow_icons{
    stroke:#FFFFFF;
}
.green_icons:hover {
    stroke: #Ffffff;
}

.popover-item {
    cursor: pointer;
}

.popover-item:hover {
    opacity: 0.7;
}

.custom-font-popover {
    font-size: 16px;
}

.custom-popover-link {
    cursor: pointer;
    padding: 0 3px 0 3px;
    border-bottom: 1px solid #D4D4D4;
}

.custom-popover-link:hover {
    opacity: 0.7;
}

.email_style {
    text-decoration: underline;
}

.profile_container_data {
    padding: 10px;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.img-preview{
    width: 100%;
    max-width: 400px!important;
    height: auto;
    margin: 10px auto;
    display: block;
}
.custom-margin-style {
    width: 100%;
}
.company_info_container{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 865px) {
    .custom-flex-style {
        flex-direction: column;
        justify-content: center;
    }

    .custom-margin-style {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 10px;
        width: 90% !important;
    }
    .custom-mt_for-lang{
        margin-top: 0!important;
    }

}

@media (max-width: 450px) {
    .inputs_container{
        flex-direction: column;
    }
    .inputs_container div{
        width: 100% !important;
    }
    .employee_list div{
        flex-direction: column;
    }
    .add_worker_container{
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-left: 0!important;
    }
    .add_worker_button{
        flex-direction: row!important;
    }
    .employee_list_container .list_header{
        display: none;
    }
    .employee_list_container{
        margin-top: 10px;
    }
    .btn_photoCompany{
        left: 115px!important;
    }
    .company_shared_text{
        font-size: 13px!important;
    }
    .company_info_container{
        margin: 0
    }
    .company_logo_container{
        width: 300px!important;
        margin-bottom: 20px;
    }
    .custom-margin-style {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 10px;
    }
    .custom-margin-style{
        width: 60%!important;
    }
    .input_company_profile{
        width: 100%!important;
    }
    .company_phone_person_container{
        flex-direction: column;
        align-items: flex-start!important;
    }
    .custom_phone_shared{
        margin-left: 0!important;
    }

    .burger_menu_dropdown_ul{
        padding: 0!important;

    }
    ul.burger_menu_dropdown_ul{
        padding-left: 0!important;
    }

}
.company_setting_title.employee{
    font-size: 22px;
}
.employee_list_width{
    width: 40%;
}
.even_row{
    background-color: #f3f3f3;
    border-radius: 7px;

}
.employee_list_width .row{
    padding: 7px;
}
.employee_list .show_more{
    padding: 7px;
    border-bottom: 1px solid green;
    cursor: pointer;
    transition: border-bottom-color 0.3ms ease;
}
.employee_list .show_more:hover{
    border-bottom-color: #10c010;
}
.color-danger{
    color: red;
}
.employee_list_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.address_marg{margin-top: 15px}
.datePickerOkButton {
    background-color: green;
    color: white;
}

.datePickerCancelButton {
    background-color: red;
    color: white;
}

.ButtonAdd {
    margin-bottom: 0 !important;
    margin-right: 10px !important;
}

.custom_error_border {
    border: 1px solid red;
}

.selected-language {
    background-color: #b9b9b9 !important;
}

.container_notFound {
    margin-top: 20px;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    flex-direction: column;
}

.notFound404 {
    font-size: 100px;

}
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    z-index: 1000;
}

.scroll-to-top.visible {
    opacity: 1;
    z-index: 1000;
}

.scroll-to-top span {
    z-index: 123123123213;
    width: 40px;
    height: 45px;
    position: fixed;
    bottom: 20px;
    right: 40px;
    border-radius: 4px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAATCAYAAACZZ43PAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAABFElEQVR42uyPu0pDURBF1w35gZQGQcWPEATt/Ac7xdrK0sJKsfYLRGxEwUJ7q+AjICjY+KgkhVgoPtAYzVk2Ewgm1yBi54Zh4MzM2vuQUkLtVoPqhlpRx3N2IAcwEMctHeVBugHK6padOlRHewH6wrlhdx2oI3mAsrqpvqt19VS9UZ/UE7UWkP12SAvQr263OS+pk3H4rM6p0+rL1++QUhoK53oM19RSuJxFoplwnFc/1KRW1THUdfUtjlfV4VieUC/UpjobbyV1IZI21WoRaAJ3wC6wCFzTqSz6PbACNIApoFYEloEdoALc0lsPAam2AOdRP9ErsAdQ4Jf6B3wPyNp64c8SFNW82VWWZZfAI3Cct/Q5AEwpziyf5QJcAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50% 48%;
    background-color: rgba(35, 35, 35, 0.65);
    transition: all 250ms linear;
}

.scroll-to-top span:hover {
    background-color: #151414;
    z-index: 1000;
}

/*.extension_left {*/
/*    text-align: center;*/
/*    position: absolute;*/
/*    font-size: 21px;*/
/*    top: 18%;*/
/*    width: 35%;*/
/*    left: 18%;*/
/*}*/
/*.extension_right {*/
/*    text-align: center;*/
/*    position: absolute;*/
/*    top: 33%;*/
/*    width: 25%;*/
/*    right: 16%;*/
/*    font-size: 21px;*/
/*}*/
/*.extension_left2 {*/
/*    font-size: 21px;*/
/*    text-align: center;*/
/*    position: absolute;*/
/*    bottom: 5%;*/
/*    width: 35%;*/
/*    left: 17%;*/
/*}*/
/*.arrowRightOnlineInspections{*/
/*    position: absolute;*/
/*    width: 160px;*/
/*    right: -11%;*/
/*    top: 21%;*/
/*}*/
/*.arrowLeftOnlineInspections{*/
/*    position: absolute;*/
/*    width: 130px;*/
/*    left: -140px;*/
/*    top: 17px;*/
/*    rotate: 10deg;*/
/*}*/
/*.arrowRightOnlineInspections2{*/
/*    position: absolute;*/
/*    margin-top: 155px;*/
/*    width: 200px;*/
/*}*/
/*.extension_right2{*/
/*    position: absolute;*/
/*    text-align: center;*/
/*    font-style: inherit;*/
/*    right: 15%;*/
/*    width: 29%;*/
/*    font-size: 21px;*/
/*    bottom: -4%;*/
/*}*/
/*.arrowLeftOnlineInspections{*/
/*    position: absolute;*/
/*    width: 130px;*/
/*    left: -140px;*/
/*    top: 52px;*/
/*    rotate: 10deg;*/
/*}
*/
.arrowRightOnlineInspections{
    width: 120px;
}
.arrowLeftOnlineInspections{
    width: 115px;
}
.arrowRightOnlineInspections2{
    width: 150px;
}
.extensionPhoto{
    margin-top: 20px;
    width: 500px;
    border-radius: 10px;
    cursor: pointer;
}
.extensionBlock{
    width: 50%;
}
.extensionBlock2{
    width: 45%;
}
.login_alias{
font-size: 22px;
    color:white;
    line-height: inherit;
}
.container_login_alias{
cursor: pointer;
}
.container_login_alias:hover{
    opacity: 0.5;
    transition: 0.5s;
}
.alias_generate_btn{
    background-color: transparent;
    border: 1px solid black;
    border-radius: 15px;
    min-width: 130px;
    padding:5px;
    font-size: 18px;
}
.alias_generate_btn:hover{
    opacity: 0.7;
    transition: 0.5s;
}
.list_of_workers{
    width:fit-content;
    cursor: pointer;
}
.workers_container{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px;
}
.row_workers{
    margin: 0 10px;
    padding-bottom:5px;
}
.btn_delete_worker{
    cursor: pointer;
}
.btn_photoCompany {
    font-size: 18px;
    border-radius: 10px;
    border: 1px solid #A4A4A4;
    padding: 5px 15px;
    position: absolute;
    bottom: -25px;
    left: 150px;
    background: white;
}
.btn_photoCompany:hover{
    opacity: 65%;
}
.workers_container::-webkit-scrollbar {
    width: 8px;
}

.workers_container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
}

.workers_container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.workers_container::-ms-scrollbar {
    width: 12px;
}

.workers_container::-ms-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
}

.workers_container::-ms-scrollbar-track {
    background-color: #f1f1f1;
}
.add_worker_container{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.add_worker_button{
    padding: 7px;
    border: 1px solid green;
    border-radius: 7px;
    transition: border 0.3s ease;
}
.add_worker_button:hover{
    border: 1px solid #10c010;
}

.input_add_workers{
    max-width: 350px;
    min-width: 300px;
}
.img-thumbnail.noPhoto{
    opacity: 0.6;
}
.invite_worker_btn{
    background: transparent;
    font-size: 18px;
    border: 1px solid rgb(30, 211, 66);
    border-radius: 10px;
    padding: 5px 10px;
    color: rgb(30, 211, 66);
    font-weight: 500;
}
.invite_worker_btn:hover{
    color: white;
    background-color: #11B64B;
    transition: 0.5s;
}
.btnCancel_modal{
    background: transparent;
    font-size: 18px;
    border: 1px solid rgb(0 0 0);
    border-radius: 10px;
    padding: 5px 10px;
    color: rgb(0 0 0);
    font-weight: 500;
}
.save_btn_modal{
    background: transparent;
    font-size: 18px;
    border: 1px solid rgb(30, 211, 66);
    border-radius: 10px;
    padding: 5px 10px;
    color: rgb(30, 211, 66);
    font-weight: 500;
}
.btnCancel_modal:hover{
    opacity: 80%;
}
.save_btn_modal:hover{
    opacity: 80%;
}
.invalid-email {
    border-color: red!important;
}
.btn_yes{
    padding: 5px 10px;
    background-color: transparent;
    border: 1px solid #11B64B;
    border-radius: 5px;
    color: #11B64B
}
.btn_yes:hover{
    transition: 0.5s;
    background-color: #11B64B;
    color:white
}
.btn_no{
    padding: 5px 10px;
    background-color: transparent;
    border: 1px solid red;
    border-radius: 5px;
    color: red;
    margin-left: 15px;
}
.btn_no:hover{
    transition: 0.5s;
    background-color: red;
    color:white
}
.custom-modal-body{
    min-width: 700px;
}
@media (max-width: 390px) {
    .company_logo_container{
        margin-left: -20px!important;
    }
    .custom-photo_container{
        width: 210px!important;
        min-width: 200px!important;
    }
}
@media (max-width: 750px) {
    .custom-modal-body{
        min-width: 500px;
    }
    .profile_container_data{
        width: 380px;
    }
    .workers_container{
        font-size: 17px;
    }
}
@media (max-width: 550px) {
    .custom-modal-body{
        min-width: 400px;
    }
    .two_inputs_modal{
        flex-direction: column;
    }
    .second-input-modal{
        margin-left: 0!important;
        margin-top: 10px;
    }
    .row_workers{
        justify-content: space-between;
    }
    .input_add_workers{
        max-width: 300px;
    }
    .custom-video-iframe{
        width: 350px!important;
    }
    .video_container{
        flex-direction: column;
        align-items: center;
    }
    .custom-video_container{
        width: 100%!important;
    }
}
.company-share_container{
    border-bottom: 1px solid #A4A4A4;
    margin-top: 15px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.custom-photo_container{
    border: 1px dotted #A4A4A4;
    padding-bottom: 10px;
    border-radius: 10px;
    min-width: 330px;
    justify-content: center;
}
.custom-input_file{
    margin-top: -15px;
}
.custom-company-img-margin{
    margin: 25px;
}
.title-img_container{
    position: absolute;
    width: 100%;
    margin-top: -16px;
    top: 0;
}
.title-img{
    background: white;
    padding: 0 10px;
    font-weight: 400;
}
.workers_title{
    padding-top: 10px!important;
}
.invite-msg{
    text-align: left!important;
}
.input_company_profile{

}
.custom-video-iframe{
     width: 1138px;
    height: 1000px;
}
@media screen and (max-width: 1200px){
    .custom-video-iframe{
        width:792px;
        height: 600px;
    }
}
.custom-video_container{
    width: 48%;
}
.company_logo_container{
    margin-left: 50px;
}
.company_shared_text{
    color:white;
    font-weight: 500;
}
.company_shared_text.name{
    font-size: 32px;
}
.company_phone_person_container{
    display: flex;
    font-weight: 500;
    align-items: center;
}
.custom_phone_shared{
    font-size: 18px;
    margin-left: 10px;
    font-weight: 400;
}
@media screen and (max-width: 767px) {
    .box-icon-md{
        margin-top: 10px;
        display: flex;
        flex-direction: column;

    }
}
.cursor-pointer{
    cursor: pointer;
}







.container.WebViewMainPage {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-top: 5px solid #28a745;
    margin-top: 10px;
}

@media (max-width: 450px){
    .container.WebViewMainPage {
        border-top: none;
        margin-top: 0;
    }
}

.WebViewMainPage h1 {
    color: #898989;
    font-size: 24px;
    text-align: center;
}
.WebViewMainPage .step {
    margin-bottom: 20px;
}
.WebViewMainPage .step-number {
    font-size: 18px;
    font-weight: bold;
    color: #28a745;
    margin-bottom: 5px;

}
.WebViewMainPage .step-description {
    margin-top: 5px;
    font-size: 16px;
    color: #555;
}
.WebViewMainPage .note {
    margin-top: 30px;
    padding: 15px;
    background-color: #e7f4ff;
    border-left: 5px solid #28a745;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
}
.WebViewMainPage  {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
}
.WebViewMainPage details {
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    background-color: #eaf9e5;
    border: 1px solid #d1f0d3;

}
.WebViewMainPage summary {
    font-weight: bold;
    cursor: pointer;
    color: #2e8b57;
    padding: 5px;
    border-radius: 4px;
}
.WebViewMainPage summary:hover {
    background-color: #d1f0d3;
}
.WebViewMainPage ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.WebViewMainPage li {
    margin: 5px 0;
    padding: 5px;
}
.WebViewMainPage a {
    text-decoration: none;
    color: #2e8b57;
}
.WebViewMainPage a:hover {
    text-decoration: underline;
}
.logo-text{
    transition: 0.5s;
}
.logo-text:hover{
    color:#f7b731!important;
}
