.pagination_container{
    bottom: 0;
    left: 45%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination_item{
    transition: font-size 0.5ms ease;
    border-radius: 6px;
    color: #636363;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
}
.pagination_item_active{
    background-color: #6cbb698c;
  padding: 0 13px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    color: #1f553a;
}
.pagination_item:hover{
    font-size: 26px;
}