.header_container{
    background: linear-gradient(to right, #11B64B, #44AB91);
    position: sticky;
    top:0;
    right: 0;
    left: 0;
    z-index: 50;
    height: 70px;
}
.wrapper{
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 16px;
}
.menu_item.selected::after {
    content: '';
    display: block;
    height: 3px;
    background-color: white;
    margin-top: 2px;
}

.logo_text{
    font-size:35px;
    font-weight: bold;
    cursor: pointer;
    color: white;
}
.menu_ul{
    display: flex;
    list-style-type: none;
    align-items: center;
    flex-direction: row;
}
.menu_item{
    font-size: 23px;
    margin-right: 40px;
    color: #ffffff;
    font-weight: 500;
}
.menu_item_profile p{
    margin-bottom: 0!important;
}
.menu_item_profile a{
    color: white!important;
}
.menu_item_profile{
    font-size: 23px;
    color: #ffffff;
    font-weight: 500;
}
.profile_img{
    margin-right: 10px;
}
.last_menu_item{
    border: 1px solid grey;
    padding: 5px 0 10px 20px ;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.last_menu_item:not(:hover){
    border: 1px solid grey;
    padding: 5px 0 10px 30px ;
    border-radius: 10px;
    transition: 0.3s;
}
ul{
    margin-bottom: 0 !important;
}
.menu_container a{
    color: white !important;
}
.last_menu_item:hover{
    background-color: black;
    color: white;
    transition: 0.3s;
    padding: 5px 10px 10px 20px ;
    color: white !important;
}
.menu_item:hover{
    color: #625c5c;
}
.login_img{
}

@media (max-width: 991px) {
    .menu_ul{
        display: none;
    }
    .link_to_profile{
        display: none!important;
    }
    .burger_menu_container{
        display: block;
    }

}

@media (min-width: 992px) {
    .menu_ul{
        display: flex;
    }
    .link_to_profile{
        display: block;
    }
    .burger_menu_container{
        display: none;
    }

}
@media (max-width: 450px){

}
