.container{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.cardContainer {
    /*justify-content: space-between;*/
    display: flex;
    flex-wrap: wrap;
}
.showMoreBtn{
    background-color: transparent;
    text-decoration: underline;
    margin-bottom: 10px;
    margin-top: 10px;
}
.showMoreP{
    font-size: 18px;
}
.title_of_header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
@media only screen and (max-width: 550px) {
    .title_of_header{
        flex-direction: column;
    }
    .title{
        margin-bottom: 10px!important;
    }
}
.title{
    font-size: 27px;
    margin-bottom: 0;
    font-weight: 500;
}
@media only screen and (max-width: 1400px) {
    .cardContainer {
        justify-content: space-around;
    }
    .cardContainer::after{
        content: '';
        margin: 10px 20px;
        width: 40%;
    }
}
@media (max-width: 576px){
    .cardContainer {
        display: block;
    }
}
@media (max-width: 479px){
    .h2  {
        font-size: 24px;
    }
}