

html {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
}

body {
    font-family: "Albert Sans", sans-serif !important;
    color: #333;
    font-weight: 300;
}

/*------------------------------------------------------------------*/
/*   IE10 in Windows 8 and Windows Phone 8 Bug fix
/*-----------------------------------------------------------------*/

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page { overflow: hidden; }

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.ind-60 { padding-right: 60px; padding-left: 60px; }
.ind-50 { padding-right: 50px; padding-left: 50px; }
.ind-45 { padding-right: 45px; padding-left: 45px; }
.ind-35 { padding-right: 35px; padding-left: 35px; }
.ind-30 { padding-right: 30px; padding-left: 30px; }
.ind-25 { padding-right: 25px; padding-left: 25px; }
.ind-20 { padding-right: 20px; padding-left: 20px; }
.ind-15 { padding-right: 15px; padding-left: 15px; }
.ind-10 { padding-right: 10px; padding-left: 10px; }
.ind-5  { padding-right: 5px; padding-left: 5px; }

.wide-160 { padding-top: 160px; padding-bottom: 160px; }
.wide-150 { padding-top: 150px; padding-bottom: 150px; }
.wide-140 { padding-top: 140px; padding-bottom: 140px; }
.wide-130 { padding-top: 130px; padding-bottom: 130px; }
.wide-120 { padding-top: 120px; padding-bottom: 120px; }
.wide-110 { padding-top: 110px; padding-bottom: 110px; }
.wide-100 { padding-top: 100px; padding-bottom: 100px; }
.wide-90 { padding-top: 100px; padding-bottom: 90px; }
.wide-80 { padding-top: 100px; padding-bottom: 80px; }
.wide-70 { padding-top: 100px; padding-bottom: 70px; }
.wide-60 { padding-top: 100px; padding-bottom: 60px; }
.wide-50 { padding-top: 50px; padding-bottom: 25px; }
.wide-40 { padding-top: 100px; padding-bottom: 40px; }
.wide-30 { padding-top: 100px; padding-bottom: 30px; }

/*------------------------------------------*/
/*    Margin Top
/*------------------------------------------*/

.mt-100 { margin-top: 100px; }
.mt-90 { margin-top: 90px; }
.mt-80 { margin-top: 80px; }
.mt-70 { margin-top: 70px; }
.mt-60 { margin-top: 60px; }
.mt-50 { margin-top: 50px; }
.mt-45 { margin-top: 45px; }
.mt-40 { margin-top: 40px; }
.mt-35 { margin-top: 35px; }
.mt-30 { margin-top: 30px; }
.mt-25 { margin-top: 25px; }
.mt-20 { margin-top: 20px; }
.mt-15 { margin-top: 15px; }
.mt-10 { margin-top: 10px; }
.mt-5 { margin-top: 5px; }

/*------------------------------------------*/
/*    Margin Bottom
/*------------------------------------------*/

.mb-100 { margin-bottom: 100px; }
.mb-90 { margin-bottom: 90px; }
.mb-80 { margin-bottom: 80px; }
.mb-70 { margin-bottom: 70px; }
.mb-60 { margin-bottom: 60px; }
.mb-50 { margin-bottom: 50px; }
.mb-45 { margin-bottom: 45px; }
.mb-40 { margin-bottom: 40px; }
.mb-35 { margin-bottom: 35px; }
.mb-30 { margin-bottom: 30px; }
.mb-25 { margin-bottom: 25px; }
.mb-20 { margin-bottom: 20px; }
.mb-15 { margin-bottom: 15px; }
.mb-10 { margin-bottom: 10px; }
.mb-5 { margin-bottom: 5px; }
.mb-0 { margin-bottom: 0; }

/*------------------------------------------*/
/*    Margin Left
/*------------------------------------------*/

.ml-70 { margin-left: 70px; }
.ml-60 { margin-left: 60px; }
.ml-50 { margin-left: 50px; }
.ml-45 { margin-left: 45px; }
.ml-40 { margin-left: 40px; }
.ml-35 { margin-left: 35px; }
.ml-30 { margin-left: 30px; }
.ml-25 { margin-left: 25px; }
.ml-20 { margin-left: 20px; }
.ml-15 { margin-left: 15px; }
.ml-10 { margin-left: 10px; }
.ml-5 { margin-left: 5px; }

/*------------------------------------------*/
/*    Margin Right
/*------------------------------------------*/

.mr-70 { margin-right: 70px; }
.mr-60 { margin-right: 60px; }
.mr-50 { margin-right: 50px; }
.mr-45 { margin-right: 45px; }
.mr-40 { margin-right: 40px; }
.mr-35 { margin-right: 35px; }
.mr-30 { margin-right: 30px; }
.mr-25 { margin-right: 25px; }
.mr-20 { margin-right: 20px; }
.mr-15 { margin-right: 15px; }
.mr-10 { margin-right: 10px; }
.mr-5 { margin-right: 5px; }

/*------------------------------------------*/
/*    Padding Top
/*------------------------------------------*/

.pt-100 { padding-top: 100px; }
.pt-90 { padding-top: 90px; }
.pt-80 { padding-top: 80px; }
.pt-70 { padding-top: 70px; }
.pt-60 { padding-top: 60px; }
.pt-50 { padding-top: 50px; }
.pt-45 { padding-top: 45px; }
.pt-40 { padding-top: 40px; }
.pt-35 { padding-top: 35px; }
.pt-30 { padding-top: 30px; }
.pt-25 { padding-top: 25px; }
.pt-20 { padding-top: 20px; }
.pt-15 { padding-top: 15px; }
.pt-10 { padding-top: 10px; }

/*------------------------------------------*/
/*    Padding Bottom
/*------------------------------------------*/

.pb-100 { padding-bottom: 100px; }
.pb-90 { padding-bottom: 90px; }
.pb-80 { padding-bottom: 80px; }
.pb-70 { padding-bottom: 70px; }
.pb-60 { padding-bottom: 60px; }
.pb-50 { padding-bottom: 50px; }
.pb-45 { padding-bottom: 45px; }
.pb-40 { padding-bottom: 40px; }
.pb-35 { padding-bottom: 35px; }
.pb-30 { padding-bottom: 30px; }
.pb-25 { padding-bottom: 25px; }
.pb-20 { padding-bottom: 20px; }
.pb-15 { padding-bottom: 15px; }
.pb-10 { padding-bottom: 10px; }
.pb-0 { padding-bottom: 0; }

/*------------------------------------------*/
/*    Padding Left
/*------------------------------------------*/

.pl-100 { padding-left: 100px; }
.pl-90 { padding-left: 90px; }
.pl-80 { padding-left: 80px; }
.pl-70 { padding-left: 70px; }
.pl-60 { padding-left: 60px; }
.pl-50 { padding-left: 50px; }
.pl-45 { padding-left: 45px; }
.pl-40 { padding-left: 40px; }
.pl-35 { padding-left: 35px; }
.pl-30 { padding-left: 30px; }
.pl-25 { padding-left: 25px; }
.pl-20 { padding-left: 20px; }
.pl-15 { padding-left: 15px; }
.pl-10 { padding-left: 10px; }
.pl-5 { padding-left: 5px; }
.pl-0 { padding-left: 0px; }

/*------------------------------------------*/
/*    Padding Right
/*------------------------------------------*/

.pr-100 { padding-right: 100px; }
.pr-90 { padding-right: 90px; }
.pr-80 { padding-right: 80px; }
.pr-70 { padding-right: 70px; }
.pr-60 { padding-right: 60px; }
.pr-50 { padding-right: 50px; }
.pr-45 { padding-right: 45px; }
.pr-40 { padding-right: 40px; }
.pr-35 { padding-right: 35px; }
.pr-30 { padding-right: 30px; }
.pr-25 { padding-right: 25px; }
.pr-20 { padding-right: 20px; }
.pr-15 { padding-right: 15px; }
.pr-10 { padding-right: 10px; }
.pr-5 { padding-right: 5px; }
.pr-0 { padding-right: 0px; }

/*------------------------------------------*/
/*   BACKGROUND SETTINGS
/*------------------------------------------*/

.bg-scroll {
    width: 100%;
    background-attachment: fixed !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.bg-fixed {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

/*------------------------------------------*/
/*   Background Colors for Sections
/*------------------------------------------*/

.bg-white { background-color: #fff; }
.bg-dark { background-color: #222!important; }
.bg-deepdark { background-color: #1a1a1a!important; }
.bg-grey { background-color: #ede9e6; }
.bg-lightgrey { background-color: #f2f2f2; }
.bg-yellow { background-color: #fed841; }
.bg-green { background-color: #48af4b; }
.bg-blue { background-color: #389bf2; }
.bg-coral { background-color: #fa5876; }
.bg-purple { background-color: #8739e5; }

/*------------------------------------------*/
/*   BORDER SETTINGS FOR DEMOS
/*------------------------------------------*/

.b-top { border-top: 1px solid #ddd; }
.b-bottom { border-bottom: 1px solid #ddd; }
.b-left { border-left: 1px solid #ddd; }
.b-right { border-right: 1px solid #ddd; }

.grey-border .b-bottom {
    margin: 0 8%;
}



/* ==========================================================================
  02.  TYPOGRAPHY
  =========================================================================== */

/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
    color: #222;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 0;
}

/* Header H5 */
h5.h5-xs { font-size: 1.125rem; }  /* 18px */
h5.h5-sm { font-size: 1.1875rem; } /* 19px */
h5.h5-md { font-size: 1.25rem;  }  /* 20px */
h5.h5-lg { font-size: 1.375rem;  } /* 22px */
h5.h5-xl { font-size: 1.5rem; }    /* 24px */

/* Header H4 */
h4.h4-xs { font-size: 1.625rem; }  /* 26px */
h4.h4-sm { font-size: 1.75rem; }   /* 28px */
h4.h4-md { font-size: 1.875rem; }  /* 30px */
h4.h4-lg { font-size: 2rem; }      /* 32px */
h4.h4-xl { font-size: 2.125rem; }  /* 34px */

/* Header H3 */
h3.h3-xs { font-size: 2.375rem; }  /* 38px */
h3.h3-sm { font-size: 2.5rem; }    /* 40px */
h3.h3-md { font-size: 2.625rem; }  /* 42px */
h3.h3-lg { font-size: 2.75rem; }   /* 44px */
h3.h3-xl { font-size: 3rem; }      /* 48px */

/* Header H2 */
h2.h2-xs { font-size: 3.25rem; }   /* 52px */
h2.h2-sm { font-size: 3.375rem; }  /* 54px */
h2.h2-md { font-size: 3.5rem; }    /* 56px */
h2.h2-lg { font-size: 3.75rem; }   /* 60px */
h2.h2-xl { font-size: 4rem; }      /* 64px */
h2.h2-huge { font-size: 5rem; }    /* 80px */

/*------------------------------------------*/
/*   PARAGRAPHS
/*------------------------------------------*/

p.p-sm { font-size: 0.95rem; }     /* 15.2px */
p { font-size: 1rem; }             /* 16px */
p.p-md { font-size: 1.125rem; }    /* 18px */
p.p-lg { font-size: 1.25rem; }     /* 20px */
p.p-xl { font-size: 1.3175rem; }   /* 22px */

/*------------------------------------------*/
/*   LISTS
/*------------------------------------------*/

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

/*------------------------------------------*/
/*   LINK SETTINGS
/*------------------------------------------*/

a {
    color: #333;
    text-decoration: none;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

a:hover {
    color: #666;
    text-decoration: none;
}

a:focus {
    outline: none;
    text-decoration: none;
}

/*------------------------------------------*/
/*   BUTTON SETTINGS
/*------------------------------------------*/

.btn {
    background-color: #ff3366;
    color: #fff;
    font-size: 0.8125rem;
    font-family: 'Montserrat', sans-serif;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 0.875rem 2rem;
    border: 2px solid #ff3366;
    overflow: hidden;
    position: relative;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.btn-arrow {
    position: relative;
}

.btn-arrow span {
    display: inline-block;
    position: relative;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    will-change: transform;
}

.btn-arrow:hover span,
.btn-arrow:focus span {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
}

.btn-arrow .svg-inline--fa {
    position: absolute;
    line-height: 1rem!important;
    right: 0;
    top: -0.5px;
    opacity: 0;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

/*------------------------------------------*/
/*   Button Size
/*------------------------------------------*/

.btn.btn-sm {
    font-size: 0.75rem;
    padding: 0.785rem 1.75rem;
}

.btn.btn-md {
    font-size: 0.9125rem;
    padding: 0.925rem 2rem;
}

.btn.btn-lg {
    font-size: 0.925rem;
    padding: 1rem 2rem;
}

/*------------------------------------------*/
/*   Button Color
/*------------------------------------------*/

.btn-arrow:hover .svg-inline--fa,
.btn-arrow:focus .svg-inline--fa {
    opacity: 1;
    right: -16px;
}

.btn-black {
    color: #fff;
    background-color: #333;
    border-color: #333;
}

.btn-white {
    color: #333;
    background-color: #fff;
    border-color: #fff;
}

.white-color .btn-white span,
.white-color .btn-yellow span {
    color: #333;
}

.btn-tra-white {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
}

.btn-tra-black {
    color: #333;
    background-color: transparent;
    border-color: #333;
}

.btn-yellow {
    color: #333;
    background-color: #ffc100;
    border-color: #ffc100;
}

.btn-blue {
    color: #fff;
    background-color: #389bf2;
    border-color: #389bf2;
}

.btn-green {
    color: #fff;
    background-color: #48af4b;
    border-color: #48af4b;
}

.btn-purple {
    color: #fff;
    background-color: #8739e5;
    border-color: #8739e5;
}

.btn-coral {
    color: #fff;
    background-color: #fa5876;
    border-color: #fa5876;
}

/*------------------------------------------*/
/*   Button Hover
/*------------------------------------------*/

.btn.tra-hover:hover,
.btn-yellow.tra-hover:hover,
.btn-blue.tra-hover:hover,
.btn-green.tra-hover:hover,
.btn-purple.tra-hover:hover,
.btn-coral.tra-hover:hover {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
}

.white-hover:hover {
    color: #333;
    background-color: #fff;
    border-color: #fff;
}

.white-hover:hover span {
    color: #333;
}

.black-hover:hover {
    color: #fff;
    background-color: #333;
    border-color: #333;
}

.btn-tra-black:hover {
    background-color: #333;
    border-color: #333;
}

.black-hover:hover span,
.btn-tra-black:hover span,
.btn-yellow.tra-hover:hover span {
    color: #fff;
}

.green-hover:hover {
    color: #fff;
    background-color: #48af4b;
    border-color: #48af4b;
}

.btn-blue:hover {
    color: #fff;
    background-color: #3188d5;
    border-color: #3188d5;
}

.btn-green:hover {
    color: #fff;
    background-color: #469248;
    border-color: #469248;
}

.btn-purple:hover {
    color: #fff;
    background-color: #7836c9;
    border-color: #7836c9;
}

.btn-coral:hover {
    color: #fff;
    background-color: #e04d68;
    border-color: #e04d68;
}

/*------------------------------------------*/
/*   Button Focus
/*------------------------------------------*/

.btn:focus {
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-black:focus {
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*------------------------------------------*/
/*   VIDEO POPUP LINK
/*------------------------------------------*/

.modal-video {
    display: inline-block;
    vertical-align: middle;
    line-height: 2.5rem;
    position: relative;
}

.modal-video a .svg-inline--fa {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-right: 5px;
    top: 7px;
    position: relative;
}

.modal-video a {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    line-height: 1;
    font-weight: 700;
}

.modal-video span {
    color: #999;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    line-height: 1;
    font-weight: 300;
    text-transform: none;
    position: absolute;
    top: 32px;
    left: 52px;
}

/*------------------------------------------*/
/*   VIDEO POPUP ICON
/*------------------------------------------*/

.video-preview {
    position: relative;
}

.video-btn {
    display: inline-block;
}

.video-block {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
}

/*------------------------------------------*/
/*  Video Button Icon
/*------------------------------------------*/
.row a{
    color: black;
}
.navbar-collapse{
    justify-content: flex-end;
}
a.nav-link{
    font-size: 18px!important;
}
.video-btn {
    width: 100px;
    height: 100px;
    position: absolute!important;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    background-color: transparent;
    color: #fff;
    line-height: 110px!important;
    border: 4px solid transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.btn-green {
    background-color: #28a745;
    color: #fff;
    border-color: #28a745;
    transition: all 0.3s ease-in-out;
    display: inline-flex;
}

.btn-green:hover {
    background-color: #fff;
    color: #28a745;
    border-color: #28a745;
}

.arrow-icon {
    margin-left: 5px;
    transition: transform 0.3s ease-in-out;
}

.btn-green:hover .arrow-icon {
    transform: translateX(5px);
}
#video-1 .video-btn {
    position: relative!important;
    margin: 0 auto;
    top: 0;
    left: 0;
}
.login-btn-text{
    font-size: 18px;
    padding-right: 10px;
}
.login-btn-true{
    background-color: #111111!important;
    border: none !important;
    padding: 15px;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    font-size: 1rem!important;
}
.login-btn{
    background-color: #28912d !important;
    border: none !important;
    padding: 15px;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    font-size: 1rem!important;
}
.login-btn:hover{
    background-color: black !important;
}
.btn-login:hover{
    background-color: black !important;
    border: 1px solid white !important;
}
.video-btn .svg-inline--fa {
    font-size: 3rem;
    line-height: 100px!important;
    margin-left: 10px;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

.video-btn:before {
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;
    top: -5px;
    bottom: -5px;
    background: rgba(255, 255, 255, 0.2);
    opacity: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

.video-btn:hover .svg-inline--fa {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
}

.video-btn:hover:before {
    opacity: .75;
    left: -25px;
    right: -25px;
    top: -25px;
    bottom: -25px;
}

/*------------------------------------------*/
/*  Video Icon Color
/*------------------------------------------*/

.video-btn.play-icon-white { background-color: #fff; color: #333; border-color: #fff; }
.video-btn.play-icon-tra {  background-color: transparent; color: #fff; border-color: #fff; }
.video-btn.play-icon-purple { background-color: #7a59e4; border-color: #7a59e4; }
.video-btn.play-icon-green { background-color: #48af4b; border-color: #48af4b; }
.video-btn.play-icon-coral { background-color: #fa5876; border-color: #fa5876; }
.video-btn.play-icon-blue { background-color: #389bf2; border-color: #389bf2; }

/*------------------------------------------*/
/*    STORE BAGE ICONS
/*------------------------------------------*/

.stores-badge {
    margin-top: 40px;
}

a.store {
    margin-right: 12px;
}

#hero-6 a.store {
    margin-right: 0;
    margin-left: 12px;
}

.footer-stores-badge a.store {
    display: block;
    margin-right: 0;
    margin-bottom: 14px;
}

.btn:last-child,
a.store:last-child {
    margin-right: 0;
}

.stores-badge p {
    font-weight: 500;
    margin-bottom: 10px;
}

.stores-badge span.os-version {
    display: block;
    font-size: 0.9rem;
    font-style: italic;
    line-height: 1;
    margin-top: 20px;
}

/*------------------------------------------*/
/*   APP DEVICES
/*------------------------------------------*/

.app-devices {
    padding: 25px 0 0 5px;
}

/*------------------------------------------*/
/*   App Devices Icons
/*------------------------------------------*/

.app-devices .svg-inline--fa {
    color: rgba(50, 50, 50, 0.3);
    font-size: 2.6rem;
    line-height: 1!important;
    float: left;
    margin-right: 0.65rem;
}

.bg-green .app-devices .svg-inline--fa,
.bg-image .app-devices .svg-inline--fa {
    color: rgba(240, 240, 240, 0.5);
}

.app-devices .svg-inline--fa.f-tablet {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.app-devices .svg-inline--fa.f-phone {
    margin-right: 1.25rem;
}

.app-devices-desc p {
    font-style: italic;
    line-height: 1.25;
    padding-right: 20%;
    margin-bottom: 0;
}

/*------------------------------------------*/
/*   Content List
/*------------------------------------------*/

ul.content-list {
    list-style: disc;
    margin-top: 20px;
    margin-left: 15px;
}

ul.content-list li {
    margin-bottom: 8px;
}

ul.content-list li p {
    margin-top: 0;
    margin-bottom: 0;
}

ul.content-list li:last-child {
    margin-bottom: 0;
}

/*------------------------------------------*/
/*  BOX ICONS
/*------------------------------------------*/

.box-icon-xs [class^="ti-"],
.box-icon-xs [class*=" ti-"] {
    font-size: 3.5rem;
    line-height: 1!important;
}

.box-icon-sm [class^="ti-"],
.box-icon-sm [class*=" ti-"] {
    font-size: 3.75rem;
    line-height: 1!important;
}

.box-icon [class^="ti-"],
.box-icon [class*=" ti-"] {
    font-size: 4rem;
    line-height: 1!important;
}

.box-icon-md [class^="ti-"],
.box-icon-md [class*=" ti-"] {
    font-size: 4.25rem;
    line-height: 1!important;
}

.box-icon-lg [class^="ti-"],
.box-icon-lg [class*=" ti-"] {
    font-size: 4.5rem;
    line-height: 1!important;
}

/*------------------------------------------*/
/*   SECTION ID
/*------------------------------------------*/

span.section-id {
    font-size: 0.8575rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    position: relative;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.bg-dark span.section-id.grey-color {
    color: #727272;
}

/*------------------------------------------*/
/*   SECTION TITLE
/*------------------------------------------*/

.section-title {
    margin-bottom: 60px;
}

.section-title h2 {
    font-weight: 600;
    letter-spacing: -0.5px;
}

.section-title p {
    margin-top: 15px;
}

.section-title.text-center p {
    padding: 0 20%;
}

/*------------------------------------------*/
/*   TEXT SETTING
/*------------------------------------------*/

.txt-300 { font-weight: 300; }
.txt-400 { font-weight: 400; }
.txt-500 { font-weight: 500; }
.txt-600 { font-weight: 600; }
.txt-700 { font-weight: 700; }
.txt-800 { font-weight: 800; }
.txt-900 { font-weight: 900; }

.txt-up { text-transform: uppercase; }

/*------------------------------------------*/
/*   TEXT COLOR
/*------------------------------------------*/

.white-color,
.white-color h2,
.white-color h3,
.white-color h4,
.white-color h5,
.white-color h6,
.white-color p,
.white-color a,
.white-color li,
.white-color i,
.white-color span { color: #fff; }

.grey-color,
.grey-color h2,
.grey-color h3,
.grey-color h4,
.grey-color h5,
.grey-color h6,
.grey-color p,
.grey-color a,
.grey-color li,
.grey-color span  { color: #666; }

.lightgrey-color,
.lightgrey-color h2,
.lightgrey-color h3,
.lightgrey-color h4,
.lightgrey-color h5,
.lightgrey-color p,
.lightgrey-color a,
.lightgrey-color li,
.white-color .lightgrey-color,
.lightgrey-color span  { color: #858585; }

.green-color,
.green-color h2,
.green-color h3,
.green-color h4,
.green-color h5,
.green-color h6,
.green-color p,
.green-color a,
.green-color li,
.green-color i,
.white-color .green-color,
.green-color span { color: #48af4b; }

.yellow-color,
.yellow-color h2,
.yellow-color h3,
.yellow-color h4,
.yellow-color h5,
.yellow-color h6,
.yellow-color p,
.yellow-color a,
.yellow-color li,
.yellow-color i,
.white-color .yellow-color,
.yellow-color span { color: #fed841; }

.blue-color,
.blue-color h2,
.blue-color h3,
.blue-color h4,
.blue-color h5,
.blue-color h6,
.blue-color p,
.blue-color a,
.blue-color li,
.blue-color i,
.white-color .blue-color,
.blue-color span { color: #389bf2; }

.coral-color,
.coral-color h2,
.coral-color h3,
.coral-color h4,
.coral-color h5,
.coral-color h6,
.coral-color p,
.coral-color a,
.coral-color li,
.coral-color i,
.white-color .coral-color,
.coral-color span { color: #fa5876; }

.purple-color,
.purple-color h2,
.purple-color h3,
.purple-color h4,
.purple-color h5,
.purple-color h6,
.purple-color p,
.purple-color a,
.purple-color li,
.purple-color i,
.white-color .purple-color,
.purple-color span { color: #7a59e4; }




/* ==========================================================================
  02.  HEADER & NAVIGATION
  =========================================================================== */

/*------------------------------------------*/
/*   LOGO IMAGE
/*------------------------------------------*/

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 5rem;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.navbar-light .navbar-nav .nav-link {
    color: #3a3a3a;
}

.bg-light {
    background-color: #fff!important;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}

/*------------------------------------------*/
/*   NAVIGATION BAR
/*------------------------------------------*/

.navbar {
    padding: 0.75rem 0;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

.navbar.bg-tra  {
    padding: 2.5rem 0;
    background-color: transparent;
}

/*------------------------------------------*/
/*   NAVIGATION MENU
/*------------------------------------------*/

.nav-item {
    margin: 0 1.4rem;
}

.navbar-expand-lg .navbar-nav .nav-link {
    font-size: 0.85rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.65rem 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.navbar-text {
    padding-top: 0;
    padding-bottom: 0;
}

/*------------------------------------------*/
/*   DROPDOWN MENU
/*------------------------------------------*/

.dropdown-menu {
    background-color: #fbfbfb;
    padding: 0.25rem 0;
    margin: 0 0 0 2px;
    border: 1px solid #ddd;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.dropdown-item {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1.25rem;
}

.dropdown-toggle::after {
    vertical-align: .275em;
}

.dropdown-item.dropdown-toggle {
    position: relative;
}

.dropdown-item.dropdown-toggle::after {
    vertical-align: .275em;
    position: absolute;
    right: 15px;
    top: 15px;
    border-left: 0.31em solid;
    border-top: 0.31em solid transparent;
    border-right: 0;
    border-bottom: 0.31em solid transparent;
}

.hover-menu .active a,
.hover-menu .active a:focus,
.hover-menu .active a:hover,
.hover-menu li a:hover,
.hover-menu li a:focus ,
.navbar > .show > a,
.navbar > .show > a:focus,
.navbar > .show > a:hover{
    color: #3a3a3a;
    background: transparent;
    outline: 0;
}

.hover-menu .collapse ul ul > li:hover > a,
.navbar .show .dropdown-menu > li > a:focus,
.navbar .show .dropdown-menu > li > a:hover,
.hover-menu .collapse ul ul ul > li:hover > a {
    background: #eee;
    color: #636363;
}

.navbar.navbar-expand-md.navbar-light{
justify-content: center;
}
.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px; /* установите высоту вашего header'а */
    background-color: #f8f9fa; /* установите цвет фона вашего header'а */
}

.toggle-button {
    flex-grow: 1;
}

.navbar-links {
    display: flex;
    flex-basis: 0;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
}

.navbar-links li {
    margin-right: 10px;
}

.navbar-links li:last-child {
    margin-right: 0;
}

.navbar-links a {
    color: #212529; /* установите цвет ссылок */
    text-decoration: none;
    font-weight: 600;
}

.navbar-links a:hover {
    color: #007bff; /* установите цвет ссылок при наведении */
}
/*@media (max-width: 991px){*/
/*    .navbar-toggler{*/
/*        display: inline-block !important;*/
/*    }*/

/*}*/
/*@media screen and (max-width: 991px){*/
/*    .collapse .navbar-collapse{*/
/*        display: none !important;*/
/*    }*/
/*}*/
/*@media (min-width: 768px){*/
/*    .navbar-expand-md .navbar-collapse {*/
/*         display: block!important;*/
/*         flex-basis: auto;*/
/*    }*/
/*}*/
/*@media (min-width: 992px){*/
/*    .navbar-expand-lg .navbar-nav {*/
/*        flex-direction: row;*/
/*        align-items: center!important;*/
/*    }*/
/*}*/
.collapse:not(.show) {
    display: none!important;
}
/*@media (min-width: 768px){*/
/*    .navbar-expand-md .navbar-collapse {*/
/*         display: ruby-base!important;*/
/*        flex-basis: auto;*/
/*    }*/
/*}*/
.collapse:not(.show) {
    display: none!important;
}
.collapse:not(.show) {
    display: none!important;
}
.collapse:not(.show) {
    display: none!important;
}
.navbar-collapse {
    flex-basis: 100%!important;
    flex-grow: 1!important;
    align-items: center!important;
}
/*@media (min-width: 768px){*/
/*    .navbar-expand-md {*/
/*        flex-wrap: inherit!important;*/
/*        justify-content: flex-start;*/
/*    }*/
/*}*/
/*@media (min-width: 768px){*/
/*    .navbar-expand-md .navbar-collapse {*/
/*         flex-basis: auto!important;*/
/*    }*/
/*}*/
/*@media only screen and (max-width: 991px) {*/

/*    .hover-menu .show > .dropdown-toggle::after{ transform: rotate(-90deg); }*/

/*}*/

@media only screen and (min-width: 991px) {

    .hover-menu .collapse ul li { position:relative; }
    .hover-menu .collapse ul li:hover > ul { display:block; }
    .hover-menu .collapse ul ul { position: absolute; top:100%; left:0; min-width:180px; display:none; }
    /*******/
    .hover-menu .collapse ul ul li{ position:relative; }
    .hover-menu .collapse ul ul li:hover > ul { display:block; }
    .hover-menu .collapse ul ul ul { position:absolute; top:0; left:100%; min-width:180px; display:none; }
    /*******/
    .hover-menu .collapse ul ul ul li {position:relative; }
    .hover-menu .collapse ul ul ul li:hover ul { display:block; }
    .hover-menu .collapse ul ul ul ul { position:absolute; top:0; left:-100%; min-width:180px; display:none; z-index:1; }

}

/*------------------------------------------*/
/*   Navbar Social Icons
/*------------------------------------------*/

.header-socials {
    margin-top: 4px;
    margin-left: 8px;
    display: inline-block;
    text-align: center;
}

.header-socials span {
    float: left;
    width: auto !important;
    display: inline-block !important;
    vertical-align: top;
    clear: none !important;
    margin-left: 7px;
}

.header-socials a {
    width: 38px;
    height: 38px;
    background-color: transparent;
    display: block;
    color: #fff;
    font-size: 1rem;
    line-height: 33px !important;
    border: 2px solid #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.navbar-light .header-socials a {
    color: #444;
    border-color: #444;
}

.navbar-light .header-socials a:hover {
    color: #fff;
}

.header-socials a.ico-facebook:hover,
.navbar.scroll .header-socials a.ico-facebook:hover { background-color: #3b5998; border-color: #3b5998; }
.header-socials a.ico-twitter:hover,
.navbar.scroll .header-socials a.ico-twitter:hover { background-color: #00a9ed; border-color: #00a9ed; }
.header-socials a.ico-behance:hover,
.navbar.scroll .header-socials a.ico-behance:hover { background-color: #2473f6; border-color: #2473f6; }
.header-socials a.ico-google-plus:hover,
.navbar.scroll .header-socials a.ico-google-plus:hover { background-color: #cd1111; border-color: #cd1111; }
.header-socials a.ico-linkedin:hover,
.navbar.scroll .header-socials a.ico-linkedin:hover { background-color: #015886; border-color: #015886; }
.header-socials a.ico-dribbble:hover,
.navbar.scroll .header-socials a.ico-dribbble:hover { background-color: #d92d84; border-color: #d92d84; }
.header-socials a.ico-instagram:hover,
.navbar.scroll .header-socials a.ico-instagram:hover { background-color: #beb3a8; border-color: #beb3a8; }
.header-socials a.ico-pinterest:hover,
.navbar.scroll .header-socials a.ico-pinterest:hover { background-color: #ac281a; border-color: #ac281a; }
.header-socials a.ico-youtube:hover,
.navbar.scroll .header-socials a.ico-youtube:hover { background-color: #cd1b20; border-color: #cd1b20; }
.header-socials a.ico-tumblr:hover,
.navbar.scroll .header-socials a.ico-tumblr:hover { background-color: #3a5976; border-color: #3a5976; }
.header-socials a.ico-vk:hover,
.navbar.scroll .header-socials a.ico-vk:hover { background-color: #3b5998; border-color: #3b5998;}

/*------------------------------------------*/
/*   Navbar Button
/*------------------------------------------*/

.navbar .btn {
    font-size: 0.8125rem;
    font-weight: 600;
    padding: 0.8rem 1.65rem;
    margin-left: 15px;
}

.navbar .btn.btn-arrow .svg-inline--fa {
    top: 1px;
}

/*------------------------------------------*/
/*   Navbar Store Badge
/*------------------------------------------*/

.header-store {
    margin-left: 5px;
}

/*------------------------------------------*/
/*   Navigation Menu Hover
/*------------------------------------------*/

.navbar-light .nav-link:focus,
.navbar-dark .nav-link:focus {
    color: #fff;
    background-color: transparent;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #ccc;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #757575;
}

/*------------------------------------------*/
/*   Navbar Scroll
/*------------------------------------------*/

.navbar.scroll.bg-tra {
    padding: 16px 0;
    background-color: #fcfcfc;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}

.navbar.scroll.bg-tra.black-scroll {
    background-color: #333;
}

.navbar.scroll.bg-tra.green-scroll {
    background-color: #48af4b;
}

.navbar.scroll.bg-tra.blue-scroll {
    background-color: #389bf2;
}

.navbar.scroll.bg-tra.coral-scroll {
    background-color: #fa5876;
}

.navbar.scroll.navbar-dark.bg-tra .nav-link {
    color: #333;
}

.navbar.scroll.bg-tra.black-scroll .navbar-nav .nav-link,
.navbar.scroll.bg-tra.green-scroll .navbar-nav .nav-link,
.navbar.scroll.bg-tra.blue-scroll .navbar-nav .nav-link,
.navbar.scroll.bg-tra.coral-scroll .navbar-nav .nav-link {
    color: #fff;
}

.navbar.scroll.bg-tra.black-scroll .navbar-nav .nav-link:hover,
.navbar.scroll.bg-tra.green-scroll .navbar-nav .nav-link:hover,
.navbar.scroll.bg-tra.blue-scroll .navbar-nav .nav-link:hover,
.navbar.scroll.bg-tra.coral-scroll .navbar-nav .nav-link:hover {
    color: #ccc;
}

.navbar.scroll .header-socials a,
.navbar.scroll .btn.btn-tra-white {
    color: #222;
    border-color: #222;
}

.navbar.scroll.black-scroll .header-socials a,
.navbar.scroll.green-scroll .header-socials a,
.navbar.scroll.blue-scroll .header-socials a,
.navbar.scroll.coral-scroll .header-socials a {
    color: #fff;
    border-color: #fff;
}

.navbar.scroll .btn.white-hover:hover {
    color: #fff;
    background-color: #333;
    border-color: #333;
}

.navbar.scroll.black-scroll .btn.btn-tra-black,
.navbar.scroll.green-scroll .btn.btn-tra-black,
.navbar.scroll.blue-scroll .btn.btn-tra-black {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
}

.navbar.scroll .btn.tra-hover:hover {
    color: #333;
    background-color: transparent;
    border-color: #333;
}

.navbar.scroll.black-scroll .btn.tra-hover:hover,
.navbar.scroll.green-scroll .btn.tra-hover:hover,
.navbar.scroll.blue-scroll .btn.tra-hover:hover,
.navbar.scroll.coral-scroll .btn.tra-hover:hover {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
}

.navbar.scroll .header-socials a:hover,
.navbar.scroll .btn.white-hover:hover span,
.navbar.scroll.black-scroll .btn.tra-hover:hover span,
.navbar.scroll.green-scroll .btn.tra-hover:hover span,
.navbar.scroll.blue-scroll .btn.tra-hover:hover span,
.navbar.scroll.coral-scroll .btn.tra-hover:hover span {
    color: #fff;
}

.navbar.scroll .btn.tra-hover:hover span {
    color: #333;
}

/*------------------------------------------*/
/*   Responsive Burger Menu
/*------------------------------------------*/

.navbar-light .navbar-toggler,
.navbar.bg-tra .navbar-toggler {
    color: #333;
    padding: 0 8px 0 0;
    font-size: 2rem;
    border: none;
}

.navbar-toggler:focus {
    outline: 0;
}

/*------------------------------------------*/
/*   Logo Image
/*------------------------------------------*/

.logo-white,
.logo-black {
    display: block;
}

.scroll.navbar-dark.bg-tra.black-scroll .logo-black,
.scroll.navbar-dark.bg-tra.green-scroll .logo-black,
.scroll.navbar-dark.bg-tra.blue-scroll .logo-black,
.scroll.navbar-dark.bg-tra.coral-scroll .logo-black,
.scroll.navbar-light.bg-tra.black-scroll .logo-black,
.scroll.navbar-light.bg-tra.green-scroll .logo-black,
.scroll.navbar-light.bg-tra.blue-scroll .logo-black,
.scroll.navbar-light.bg-tra.coral-scroll .logo-black,
.navbar-light.bg-light .logo-white,
.navbar-dark.bg-tra .logo-black,
.navbar-light.bg-tra .logo-white,
.scroll.navbar-dark.bg-tra .logo-white,
.navbar-dark.bg-dark .logo-black,
.navbar-light.bg-light .logo-white {
    display: none;
}

.scroll.navbar-dark.bg-tra.black-scroll .logo-white,
.scroll.navbar-dark.bg-tra.green-scroll .logo-white,
.scroll.navbar-dark.bg-tra.blue-scroll .logo-white,
.scroll.navbar-dark.bg-tra.coral-scroll .logo-white,
.scroll.navbar-light.bg-tra.black-scroll .logo-white,
.scroll.navbar-light.bg-tra.green-scroll .logo-white,
.scroll.navbar-light.bg-tra.blue-scroll .logo-white,
.scroll.navbar-light.bg-tra.coral-scroll .logo-white,
.navbar-dark.bg-tra .logo-white,
.navbar-light.bg-tra .logo-black,
.scroll.navbar-dark.bg-tra .logo-black,
.navbar-dark.bg-dark .logo-white,
.navbar-light.bg-light .logo-black {
    display: block;
}




/* ==========================================================================
  03.  HERO
  =========================================================================== */

.hero-section .container {
    position: relative;
    z-index: 3;
}

#hero-3.hero-section .container,
#hero-6.hero-section .container,
#hero-7.hero-section .container,
#hero-10.hero-section .container,
#hero-12.hero-section .container {
    overflow: hidden;
}



.hero-2-brands {
    padding-top: 40px;
    padding-bottom: 40px;
}


#hero-5-content {
    padding-top: 180px;
    padding-bottom: 380px;
}

#hero-6 {
    background-image: url(../../assets/img/hero-6.jpg);
    padding-top: 180px;
}


#hero-9-content {
    padding-top: 180px;
    padding-bottom: 380px;
}

#hero-10 {
    padding-top: 160px;
}



/*------------------------------------------*/
/*  HERO TEXT
/*------------------------------------------*/

#hero-1 .hero-txt {
    margin-top: 80px;
}

#hero-6 .hero-txt {
    padding-left: 30px;
    padding-right: 60px;
}

#hero-7 .hero-txt {
    padding-left: 60px;
    padding-right: 30px;
}

/*------------------------------------------*/
/*  HERO APP LOGO
/*------------------------------------------*/

.hero-app-logo {
    margin-bottom: 25px;
}

#hero-12 .hero-app-logo {
    margin-bottom: 50px;
}

/*------------------------------------------*/
/*   Hero Headers
/*------------------------------------------*/

#hero-1 h2 {
    color: #000;
    font-size: 4.5rem;
    font-weight: 700;
    letter-spacing: -2px;
    margin-bottom: 25px;
}

#hero-1 h3 {
    font-weight: 300;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
}

#hero-2 h2 {
    line-height: 1.15;
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 30px;
}

#hero-3 h2 {
    font-weight: 400;
    letter-spacing: -1px;
    margin-bottom: 25px;
}

#hero-4 h2 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0;
    margin-bottom: 25px;
}

#hero-5 h2 {
    font-size: 3.85rem;
    font-weight: 400;
    letter-spacing: -1px;
    padding: 0 3%;
    margin-bottom: 25px;
}

#hero-6 h2 {
    font-size: 5.5rem;
    font-weight: 800;
    letter-spacing: -2px;
    margin-bottom: 25px;
}
@media (max-width: 450px) {
    .store{
        margin-top: 10px;
    }
    #hero-6 h2 {
    font-size: 3.5rem;
    }
}
@media (max-width: 1200px) {
    #hero-6 h2 {
        font-size: 4.5rem;
    }
}
@media (max-width: 1200px) {
    #hero-6 h2 {
        font-size: 4.5rem;
    }
}
@media (max-width: 992px) {
    #hero-6 h2 {
        font-size: 3.5rem;
    }

}
@media (max-width: 380px) {
    #hero-6 h2 {
        font-size: 3rem;
    }
    .cbox-1-txt{
        min-width: 85px;
    }
}
#hero-6 h3 {
    font-weight: 300;
    letter-spacing: 0;
}

#hero-7 h2 {
    letter-spacing: -1.5px;
    margin-bottom: 25px;
}

#hero-8 h2 {
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 30px;
}

#hero-9 h2 {
    letter-spacing: -1.5px;
    margin-bottom: 25px;
}

#hero-10 h3 {
    letter-spacing: -0.5px;
    margin-bottom: 25px;
}

#hero-11 h2 {
    letter-spacing: -1.5px;
    margin-bottom: 25px;
}

#hero-12 h2 {
    font-weight: 600;
    letter-spacing: -0.5px;
    margin-bottom: 15px;
}

/*------------------------------------------*/
/*   Hero Paragraphs
/*------------------------------------------*/

#hero-1 .hero-txt p {
    padding-right: 8%;
}

#hero-2 .hero-txt p,
#hero-4 .hero-txt p,
#hero-8 .hero-txt p,
#hero-10 .hero-txt p,
#hero-11 .hero-txt p {
    padding-right: 10%;
}

#hero-3 .hero-txt p,
#hero-5 .hero-txt p,
#hero-9 .hero-txt p {
    padding: 0 15%;
    margin-bottom: 30px;
}

/*------------------------------------------*/
/*   HERO IMAGE
/*------------------------------------------*/

.hero-img {
    text-align: center;
}

.hero-1-img {
    position: relative;
}

.hero-1-img img {
    position: relative;
    max-width: none;
    display: inline-block;
}

.hero-3-img {
    text-align: center;
    margin-top: 60px;
}

.hero-2-img,
.hero-4-img,
.hero-11-img {
    text-align: center;
    margin-bottom: -100px;
    position: relative;
    z-index: 999;
    position: relative;
}

.hero-5-image img {
    margin-top: -310px;
}

.hero-6-img {
    padding-left: 60px;
}

.hero-7-img {
    padding-right: 60px;
}

.hero-9-image img {
    margin-top: -320px;
}

.hero-8-img {
    position: relative;
}

.hero-8-img img {
    position: relative;
    max-width: none;
    display: inline-block;
}

/*------------------------------------------*/
/*    HERO NEWSLETTER FORM
/*------------------------------------------*/

.hero-section .newsletter-form {
    margin-top: 40px;
    margin-right: 15%;
}

/*------------------------------------------*/
/*    Newsletter Form Input
/*------------------------------------------*/

.hero-section .newsletter-form .form-control {
    height: 58px;
    background-color: #fff;
    border: 1px solid #eee;
    border-right: none;
    color: #222;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 1.4rem;
    box-shadow: none;
    -webkit-border-radius: 8px 0 0 8px;
    -moz-border-radius: 8px 0 0 8px;
    -o-border-radius: 8px 0 0 8px;
    border-radius: 8px 0 0 8px;
}

.hero-section .newsletter-form .form-control:focus {
    border: 1px solid #eee;
    border-right: none;
    outline: 0;
    box-shadow: none;
}

/*------------------------------------------*/
/*    Newsletter Form Placeholder
/*------------------------------------------*/

.hero-section .newsletter-form .form-control::-moz-placeholder { color: #999; }
.hero-section .newsletter-form .form-control:-ms-input-placeholder { color: #999; }
.hero-section .newsletter-form .form-control::-webkit-input-placeholder { color: #999; }

/*------------------------------------------*/
/*    Newsletter Form Button
/*------------------------------------------*/

.hero-section .newsletter-form .btn {
    width: 100%;
    height: 58px;
    background-color: #fff;
    border: 1px solid #eee;
    border-left: none;
    color: #fa5876;
    font-size: 1.25rem!important;
    padding: 0 1.25rem;
    -webkit-border-radius: 0 8px 8px 0;
    -moz-border-radius: 0 8px 8px 0;
    -o-border-radius: 0 8px 8px 0;
    border-radius: 0 8px 8px 0;
}

.hero-section .newsletter-form .btn:hover {
    color: #444;
}

/*------------------------------------------*/
/*    HERO LINKS
/*------------------------------------------*/

.hero-links {
    margin-top: 10px;
}

.hero-links span {
    font-size: 1rem;
    line-height: 1;
    margin-right: 25px;
}

.hero-links span:last-child {
    margin-right: 0
}

.hero-links span a {
    text-decoration: underline;
}

.hero-links span a:hover {
    opacity: .8;
}




/* ==========================================================================
  04.  FEATURES
  =========================================================================== */

h2.tra-digit {
    font-size: 5rem;
    font-weight: 800;
    line-height: 1;
    letter-spacing: -1px;
    position: relative;
    margin-bottom: 15px;
    margin-left: -8px;
}

/*------------------------------------------*/
/*   FEATURE BOX
/*------------------------------------------*/

.fbox-1 {
    text-align: center;
    margin-bottom: 40px;
}

.fbox-2 {
    text-align: center;
    margin-bottom: 40px;
    padding: 0 10px;
}

.fbox-2 span {
    color: #fff;
    padding: 22px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.fbox-2 span.bg-lightgrey {
    color: #999;
}

.fbox-3 {
    position: relative;
    text-align: center;
    background-color: #fff;
    border: 1px solid #eee;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 70px 25px 55px;
    margin-bottom: 30px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.fbox-3 .box-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    opacity: 0;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.fbox-3 span {
    color: #999;
    background-color: #eee;
    padding: 25px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.fbox-3:hover {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.fbox-3:hover h5 {
    color: #222;
}

.fbox-3.coral-hover:hover { border-bottom: 1px solid #fa5876; }
.fbox-3.coral-hover:hover .box-line { background-color: #fa5876; opacity: 1; }
.fbox-3.coral-hover:hover > span { background-color: #fa5876; color: #fff; }

.fbox-3.lightgreen-hover:hover { border-bottom: 1px solid #48af4b; }
.fbox-3.lightgreen-hover:hover .box-line { background-color: #48af4b; opacity: 1; }
.fbox-3.lightgreen-hover:hover > span { background-color: #48af4b; color: #fff; }

.fbox-3.blue-hover:hover { border-bottom: 1px solid #389bf2; }
.fbox-3.blue-hover:hover .box-line { background-color: #389bf2; opacity: 1; }
.fbox-3.blue-hover:hover > span { background-color: #389bf2; color: #fff; }

.fbox-4,
.fbox-5,
.fbox-6,
.fbox-7 {
    margin-bottom: 40px;
}

/*------------------------------------------*/
/*   FEATURE BOX TYPOGRAPHY
/*------------------------------------------*/

.fbox-1 h5,
.fbox-4 h5,
.fbox-6 h5 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.fbox-2 h5 {
    margin-top: 45px;
    margin-bottom: 10px;
}

.fbox-3 h5 {
    color: #757575;
    margin-top: 45px;
    margin-bottom: 10px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.fbox-5-txt {
    overflow: hidden;
    padding-left: 25px;
}

.fbox-7 h5 {
    margin-bottom: 10px;
}

.fbox-1 p, .fbox-2 p,
.fbox-3 p, .fbox-4 p,
.fbox-5 p, .fbox-6 p, .fbox-7 p {
    line-height: 1.5;
    margin-bottom: 0;
}

/*------------------------------------------*/
/*   FEATURE BOX ICON
/*------------------------------------------*/

.fbox-2 span {
    font-size: 2.75rem;
    line-height: 1!important;
}

.fbox-5 span {
    text-align: center;
    float: left;
}

/*------------------------------------------*/
/*   FEATURES IMAGE
/*------------------------------------------*/

.features-img {
    text-align: center;
    margin-bottom: 40px;
}

.features-6-img {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
}




/* ==========================================================================
  05.  CONTENT
  =========================================================================== */

#content-4 .container,
#content-9 .container {
    overflow: hidden;
}


#content-4.bg-green,

#content-9 .section-title {
    margin-bottom: 40px;
}

#content-5 .bg-inner,
#content-6 .bg-inner {
    position: relative;
    z-index: 1;
}

#content-10 .bg-inner {
    position: relative;
    z-index: 1;
}


.content-txt {
    padding-right: 30px;
    padding-left: 30px;
    margin-bottom: 40px;
}

#content-8 .content-txt {
    padding-right: 60px;
    padding-left: 30px;
}

#content-10 .content-txt {
    padding-right: 10%;
    padding-left: 10%;
}

/*------------------------------------------*/
/*   CONTENT TEXT
/*------------------------------------------*/

.content-txt h3.h3-xl {
    font-weight: 600;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
}

#content-10 .content-txt h3.h3-xl {
    margin-bottom: 15px;
}

.content-txt h5.h5-sm {
    margin-top: 20px;
    margin-bottom: 15px;
}

#content-10 .content-txt p {
    padding: 0 5%;
}

.content-txt .btn {
    margin-top: 20px;
}

/*------------------------------------------*/
/*    CONTENT BOXES
/*------------------------------------------*/

.content-boxes {
    position: relative;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 50px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    margin: 0 5% -100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.cbox-1-txt {
    overflow: hidden;
    padding-left: 25px;
}

.cbox-1 p {
    line-height: 1.5;
    margin-bottom: 0;
}

.cbox-1 span {
    text-align: center;
    float: left;
}

/*------------------------------------------*/
/*   CONTENT TEXT TYPOGRAPHY
/*------------------------------------------*/

#content-9 p {
    padding: 0 15%;
}

/*------------------------------------------*/
/*   CONTENT IMAGE
/*------------------------------------------*/

.content-img {
    text-align: center;
    margin-bottom: 40px;
}

.content-5-img,
.content-6-img {
    text-align: center;
    position: relative;
    z-index: 999;
}

.content-5-img {
    margin-bottom: -80px;
}

.content-6-img {
    margin-top: -100px;
}

.content-7-img {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
}

.content-7-img img {
    position: relative;
    max-width: none;
    display: inline-block;
}




/* ===================================================================================
  06.  SCREENSHOTS
  =================================================================================== */

/*------------------------------------------*/
/*   SCREENSHOTS CAROUSEL
/*------------------------------------------*/

.screenshots-wrap {
    position: relative;
    margin-bottom: 100px;
}

.slick-slide {
    width: 298px;
}

.carousel-item {
    opacity: 0.25;
    -webkit-transform: scale(.9);
    -ms-transform: scale(.9);
    transform: scale(.9);
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
}

.carousel-item.slick-current {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

/*------------------------------------------*/
/*   Screens Slick Carousel Dots
/*------------------------------------------*/

.screens-section .slick-dots {
    position: absolute;
    z-index: 4;
    bottom: -100px;
}

.screens-section .slick-dots li {
    margin: 0 2px;
    width: 15px;
    height: 15px;
}

.screens-section .slick-dots li button:before,
.screens-section.bg-lightgrey .slick-dots li button:before {
    content: "";
    background: #ccc;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    opacity: 1;
    width: 8px;
    height: 8px;
    left: auto;
    -webkit-transition: all ease-in-out 0.2s;
    transition: all ease-in-out 0.2s;
}

.screens-section .slick-dots li button:hover:before,
.screens-section .slick-dots li.slick-active button:before {
    background: #fff;
}

.screens-section .slick-dots li button:hover:before,
.screens-section .slick-dots li.slick-active button:before {
    background: #777;
}



#video-2 .bg-inner {
    position: relative;
    z-index: 1;
}

#video-2 .section-title {
    margin-bottom: 30px;
}

/*------------------------------------------*/
/*   VIDEO TYPOGRAPHY
/*------------------------------------------*/

#video-1 h2 {
    font-weight: 600;
    margin-top: 35px;
    margin-bottom: 25px;
}

#video-1 p {
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 0;
}

#video-2 .video-preview {
    text-align: center;
    position: relative;
    margin-bottom: -100px;
    z-index: 999;
}




/* ==========================================================================
  07. PRICING TABLES
  =========================================================================== */

/*------------------------------------------*/
/*   PRICING TABLE
/*------------------------------------------*/

.pricing-table {
    text-align: center;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 60px 30px;
    margin-bottom: 40px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

#pricing-2 .pricing-table {
    padding: 60px 35px;
}

/*------------------------------------------*/
/*    Pricing Table Price Plan
/*------------------------------------------*/

.pricing-plan h5,
#pricing-2 .price h5 {
    color: #999;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
}

.pricing-table span.price,
#pricing-2 .price span {
    font-size: 70px;
    line-height: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.pricing-plan sup,
#pricing-2 .price sup {
    font-size: 45px;
    line-height: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    top: -12px;
    right: 2px;
}

.pricing-plan sup.pricing-coins {
    top: -15px;
    font-size: 45px;
    margin-left: 1px;
}

#pricing-2 .price span.price-vat {
    display: block;
    color: #999;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 300;
    margin-top: 20px;
    padding: 0 7%;
}

p.validity {
    color: #888;
    font-style: italic;
    font-size: 1.2rem;
    margin-top: 5px;
}

/*------------------------------------------*/
/*    Pricing Description
/*------------------------------------------*/

.pricing-text {
    margin-top: 20px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
}

.pricing-text p {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 0;
}

.pricing-table p span {
    color: #000;
    font-weight: 800;
}

/*------------------------------------------*/
/*    Pricing Table Body
/*------------------------------------------*/

.pricing-table ul.features {
    padding: 10px 0 35px;
}

.pricing-table ul.features li {
    font-size: 1.05rem;
    line-height: 1;
    font-weight: 300;
    padding: 8px 0;
}

.pricing-table ul.features li:last-child {
    border-bottom: none;
}




/* ==========================================================================
  08.  TESTIMONIALS
  =========================================================================== */

/*------------------------------------------*/
/*   TESTIMONIALS CAROUSEL
/*------------------------------------------*/

.review-1 {
    opacity: 0.3;
    text-align: center;
    margin-bottom: 40px;
    padding: 0;
    -webkit-transform: scale(.85);
    -ms-transform: scale(.85);
    transform: scale(.85);
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
}

.review-1 .review-txt {
    background-color: #fff;
    border: 1px solid #eee;
    padding: 45px 40px 55px;
    margin: 0 2px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.review-1.slick-current {
    opacity: 1;
}

.review-1.slick-current {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.review-2 {
    background-color: #fff;
    border: 1px solid #ddd;
    margin: 0 10px 40px;
    padding: 30px 20px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
}

.review-3 {
    background-color: #fff;
    border: 1px solid #ddd;
    margin: 0 5px 40px;
    padding: 30px 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
}

/*------------------------------------------*/
/*    Testimonial Message Avatar
/*------------------------------------------*/

#reviews-1 img,
#reviews-2 img,
#reviews-3 img {
    width: 90px;
    height: 90px;
    display: inline-block;
    margin: -45px auto 15px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
}

#reviews-2 img {
    width: 80px;
    height: 80px;
    margin: 0 15px 0 0;
}

#reviews-3 img {
    width: 60px;
    height: 60px;
    margin: 0 10px 0 0;
}

#reviews-2 .testimonial-avatar,
#reviews-3 .testimonial-avatar {
    display: inline-block;
    float: left;
}

/*------------------------------------------*/
/*   Testimonial Message Text
/*------------------------------------------*/

.review-1 .review-txt h5 {
    margin-bottom: 15px;
}

.review-1 p,
.review-2 p,
.review-3 p {
    color: #757575;
    font-size: 1rem;
    line-height: 1.45;
    font-weight: 300;
    font-style: italic;
}

.review-2 p {
    padding: 15px 8px 0;
}

.review-3 p {
    padding: 0 8px 3px;
}

.review-author h5 {
    font-size: 1.15rem;
    line-height: 1;
    margin-bottom: 2px;
}

#reviews-2 .review-author h5 {
    padding-top: 20px;
}

#reviews-3 .review-author h5 {
    font-size: 0.975rem;
    padding-top: 12px;
    margin-bottom: 0px;
}

/*------------------------------------------*/
/*   App Rating
/*------------------------------------------*/

.app-rating .svg-inline--fa {
    color: #ffc832;
    font-size: 0.8rem;
    line-height: 1;
    margin-right: 1px;
}

.app-rating .svg-inline--fa:last-child {
    margin-right: 0;
}

/*------------------------------------------*/
/*   Reviews FlexSlider Navigation
/*------------------------------------------*/

.coral-nav.owl-theme .owl-dots .owl-dot.active span,
.coral-nav.owl-theme .owl-dots .owl-dot:hover span {
    background: #fa5876;
}

.green-nav.owl-theme .owl-dots .owl-dot.active span,
.green-nav.owl-theme .owl-dots .owl-dot:hover span {
    background: #48af4b;
}

.blue-nav.owl-theme .owl-dots .owl-dot.active span,
.blue-nav.owl-theme .owl-dots .owl-dot:hover span {
    background: #389bf2;
}

/*------------------------------------------*/
/*   Reviews Slick Carousel Dots
/*------------------------------------------*/

.reviews-section .slick-dots {
    position: absolute;
    z-index: 4;
    bottom: -30px;
}

.reviews-section .slick-dots li {
    margin: 0 2px;
    width: 15px;
    height: 15px;
}

.reviews-section .slick-dots li button:before {
    content: "";
    background: #ccc;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    opacity: 1;
    width: 8px;
    height: 8px;
    left: auto;
    -webkit-transition: all ease-in-out 0.2s;
    transition: all ease-in-out 0.2s;
}

.reviews-section .slick-dots li button:hover:before {
    background: #999;
}

.reviews-section .slick-dots li.slick-active button:before {
    background: #666;
}




/* ==========================================================================
  09.  STATISTIC
  =========================================================================== */

.statistic-block {
    text-align: center;
    margin-bottom: 40px;
}

.small-statistic .statistic-block {
    text-align: left;
    margin-bottom: 0;
}

/*------------------------------------------*/
/*    Statistic Block Number
/*------------------------------------------*/

.statistic-block p.statistic-number {
    font-size: 3.5rem;
    font-family: 'Montserrat', sans-serif;
    line-height: 1;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
}

#statistic-2 .statistic-block p.statistic-number {
    margin-top: 22px;
}

.statistic-block p {
    font-size: 0.875rem;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0;
    text-transform: uppercase;
    margin-bottom: 0;
}

.small-statistic .statistic-block p {
    margin-top: 0;
    margin-left: 10px;
}




/* ==========================================================================
  10.  BRANDS
  =========================================================================== */

#brands-1,
#brands-2 {
    padding-top: 60px;
    padding-bottom: 30px;
}

.brands-section p {
    font-weight: 400;
    margin-bottom: 35px;
}

/*------------------------------------------*/
/*    BRANDS LOGO HOLDER
/*------------------------------------------*/

#brands-1 .brand-logo,
#brands-2 .brand-logo,
.brands-static .brand-logo {
    margin-bottom: 30px;
}

#brands-1 .brand-logo img ,
#brands-2 .brand-logo img {
    padding: 0 15px;
}




/* ===================================================================================
  11.  MORE APPS
  =================================================================================== */

.m-img {
    background-color: #f6f6f6;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 60px 40px;
}

/*------------------------------------------*/
/*    MORE APPS TYPOGRAPHY
/*------------------------------------------*/

.more-app-box h5 {
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.5px;
    margin-bottom: 8px;
    margin-top: 35px;
}

.more-app-box span.app-cat {
    color: #888;
    font-size: 0.95rem;
    line-height: 1;
    display: block;
    margin-bottom: 15px;
}

.more-app-box a {
    color: #888;
    margin-right: 12px;
}

.m-links a span {
    text-decoration: underline;
    margin-left: 1px;
}

.more-app-box a:hover {
    color: #222;
}




/* ==========================================================================
  12.  PRESS
  =========================================================================== */

/*------------------------------------------*/
/*    PRESS LOGO
/*------------------------------------------*/

.press-logo {
    overflow: hidden;
    position: relative;
    background-color: transparent;
    background-color: #fff;
    padding: 70px 30px;
    margin-bottom: 40px;
    text-align: center;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
}

.press-logo img {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -moz-transform: scale(1);
    overflow: hidden;
    -webkit-transition: transform 450ms;
    -moz-transition: transform 450ms;
    -o-transition: transform 450ms;
    transition: transform 450ms;
}

.press-logo:hover {
    background-color: #fff;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.press-logo:hover img {
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -moz-transform: scale(1.1);
}

/*------------------------------------------*/
/*    PRESS TEXT
/*------------------------------------------*/

.press-txt p {
    margin-bottom: 30px;
    padding: 0 10%;
}




/* ==========================================================================
  13.  FAQs
  =========================================================================== */

#faqs-page {
    margin-top: 180px;
    margin-bottom: 80px;
}

#faqs-left {
    padding-right: 20px;
}

#faqs-right {
    padding-left: 20px;
}

/*------------------------------------------*/
/*    FAQs TYPOGRAPHY
/*------------------------------------------*/

#faqs-page h4.h4-xs {
    margin-bottom: 25px;
    text-decoration: underline;
}

.question {
    margin-bottom: 25px;
}

.question h5 {
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-bottom: 8px;
}

.question p {
    color: #666;
}

.more-questions-btn {
    margin-top: 50px;
}

.more-questions-btn h5 a {
    font-weight: 600;
    letter-spacing: -0.5px;
    text-decoration: underline;
}




/* ==========================================================================
  14.  DOWNLOAD
  =========================================================================== */

#download-1 {
    padding-top: 100px;
    padding-bottom: 0;
}


#download-2.bg-image {
    background-image: url(../../assets/img/download-2.webp);
}


/*------------------------------------------*/
/*   DOWNLOAD TYPOGRAPHY
/*------------------------------------------*/

#download-1 h2 {
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 25px;
}

#download-2 h2 {
    font-weight: 500;
    letter-spacing: -1px;
    margin-bottom: 15px;
}

#download-3 h2 {
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 15px;
}

#download-1 p,
#download-3 p {
    padding-right: 15%;
}

#download-2 p {
    padding: 0 10%;
}

#download-2 .stores-badge {
    margin-top: 25px;
}

.download-page-txt h2 {
    font-weight: 400;
    letter-spacing: -1.5px;
    margin-top: 45px;
    margin-bottom: 30px;
    padding: 0 5%;
}

.download-page-txt p.p-lg {
    font-weight: 400;
    margin-bottom: 40px;
}

p.download-notice {
    padding: 0 15%;
}

.download-page-txt p a {
    font-weight: 500;
    text-decoration: underline;
}

.download-page-txt p a:hover {
    color: #999;
}

.download-page-icon [class^="ti-"],
.download-page-icon [class*=" ti-"] {
    color: #777;
    font-size: 10rem;
    line-height: 1!important;
}

/*------------------------------------------*/
/*    DOWNLOAD IMAGE
/*------------------------------------------*/

.download-1-img {
    position: relative;
}

.download-1-img img {
    position: relative;
    max-width: none;
    display: inline-block;
}

.download-3-img {
    margin-top: -60px;
    margin-bottom: -60px;
    position: relative;
    z-index: 999;
}




/* ==========================================================================
  15.  NEWSLETTER
  =========================================================================== */

#newsletter-1 .section-title {
    margin-bottom: 15px;
}

/*------------------------------------------*/
/*    NEWSLETTER TYPOGRAPHY
/*------------------------------------------*/

.newsletter-txt p {
    color: #999;
    margin-top: 20px;
    margin-bottom: 0;
}

.newsletter-txt p a {
    color: #666;
    text-decoration: underline;
}

.newsletter-txtp a:hover {
    color: #000;
}

/*------------------------------------------*/
/*    Newsletter Form Input
/*------------------------------------------*/

.newsletter-section .newsletter-form .form-control {
    height: 62px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-right: none;
    color: #222;
    font-size: 1.05rem;
    font-weight: 300;
    padding: 0 1.4rem;
    box-shadow: none;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -o-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.newsletter-section .newsletter-form .form-control:focus {
    border: 1px solid #eee;
    border-right: none;
    outline: 0;
    box-shadow: none;
}

/*------------------------------------------*/
/*    Newsletter Form Placeholder
/*------------------------------------------*/

.newsletter-section .newsletter-form .form-control::-moz-placeholder { color: #999; }
.newsletter-section .newsletter-form .form-control:-ms-input-placeholder { color: #999; }
.newsletter-section .newsletter-form .form-control::-webkit-input-placeholder { color: #999; }

/*------------------------------------------*/
/*    Newsletter Form Button
/*------------------------------------------*/

.newsletter-section .newsletter-form .btn {
    width: 100%;
    height: 62px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-left: none;
    color: #fa5876;
    font-size: 1.25rem!important;
    padding: 0 1.5rem;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.newsletter-section .newsletter-form .btn:hover {
    color: #444;
}




/* ==========================================================================
  16.  BLOG
  =========================================================================== */

#blog-1 .container {
    overflow: hidden;
}

#blog-page,
#single-blog-page {
    margin-top: 80px;
}

/*------------------------------------------*/
/*    BLOG POST
/*------------------------------------------*/

.blog-post {
    margin-bottom: 40px;
}

.posts-holder .blog-post,
.posts-holder .blog-post-video {
    margin-bottom: 70px;
}

/*------------------------------------------*/
/*    BLOG TYPOGRAPHY
/*------------------------------------------*/

.blog-post h5 {
    font-weight: 600;
    margin-bottom: 15px;
}

.sblog-post-txt h4.h4-md {
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 20px;
}

.blog-post-txt p {
    margin-bottom: 35px;
}

.blog-post-txt span {
    font-size: 0.95rem;
    display: block;
    margin-bottom: 12px;
}

.blog-post-meta span {
    color: #888;
    font-size: 0.95rem;
    margin-right: 30px;
}

.blog-post a,
.blog-post-video a {
    color: #111;
    font-weight: 600;
    text-decoration: underline;
}

.blog-post a:hover,
.blog-post-video a:hover {
    color: #888;
}

/*------------------------------------------*/
/*    BLOG PAGE RIGHT SIDEBAR
/*------------------------------------------*/

#sidebar-right h5.h5-sm,
#sidebar-left h5.h5-sm {
    margin-bottom: 25px;
}

/*------------------------------------------*/
/*   Blog Search Form
/*------------------------------------------*/

#search-field .form-control {
    background-color: #f0f0f0;
    padding-left: 20px;
    border: none;
    -webkit-border-radius: 6px 0 0 6px;
    -moz-border-radius: 6px 0 0 6px;
    border-radius: 6px 0 0 6px;
}

#search-field .btn {
    background-color: #f0f0f0;
    color: #777;
    border: none;
    padding: 22px 18px;
    margin-top: 0;
    -webkit-box-shadow: 0 0 0;
    -moz-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    -webkit-border-radius: 0px 6px 6px 0;
    -moz-border-radius: 0px 6px 6px 0;
    border-radius: 0px 6px 6px 0;
}

#search-field .form-control:focus {
    border-color: #999;
    outline: 0px none;
    box-shadow: none;
}

/*------------------------------------------*/
/*   Blog Categories
/*------------------------------------------*/

ul.blog-category-list > li {
    padding: 10px 0;
    border-bottom: 1px dashed #c0c0c0;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

ul.blog-category-list > li:first-child {
    padding: 0 0 10px;
}

ul.blog-category-list > li:last-child {
    padding: 10px 0 0;
    border-bottom: none;
}

ul.blog-category-list > li a,
ul.blog-category-list > li span {
    color: #555;
}

ul.blog-category-list li .svg-inline--fa {
    margin-right: 10px;
}

ul.blog-category-list li a:hover {
    color: #000;
    text-decoration: none;
}

/*------------------------------------------*/
/*   Popular Posts
/*------------------------------------------*/

.popular-posts li {
    padding: 14px 0;
    border-bottom: 1px dashed #c0c0c0;
}

.popular-posts li:first-child {
    padding: 0 0 14px;
}

.popular-posts li:last-child {
    padding: 14px 0 0;
    border-bottom: 0;
}

.popular-posts img {
    text-align: center;
    float: left;
}

.post-summary {
    overflow: hidden;
    padding-left: 20px;
}

/*------------------------------------------*/
/*  Popular Post Typography
--------------------------------------------*/

.post-summary a {
    color: #555;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

.popular-posts .post-summary a:hover {
    text-decoration: underline;
}

.popular-posts p {
    color: #888;
    font-size: 0.875rem;
    margin-top: 6px;
    margin-bottom: 0;
}

/*------------------------------------------*/
/*   Blog Page Popular Tags
/*------------------------------------------*/

.badge {
    font-size: 0.75rem;
    font-weight: 600;
    color: #222;
    border: none;
    background-color: #f0f0f0;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
    margin-bottom: 8px;
    padding: 5px 10px;
    margin-right: 2px;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
}

.badge a {
    color: #666!important;
    font-size: 0.75rem;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.42857;
}

.badge:hover {
    background-color: #2c353f;
    border-color: #2c353f;
    color: #fff;
}

.badge:hover a {
    color: #fff!important;
}

/*------------------------------------------*/
/*    BLOG PAGE PAGINATION
/*------------------------------------------*/

.page-link {
    color: #666;
    padding: .65rem .95rem;
}

.page-link:hover,
.page-link:focus {
    color: #444;
    background-color: #eee;
    border-color: #dee2e6;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #333;
    border-color: #333;
}

/*------------------------------------------*/
/*    SINGLE POST SHARE ICONS
/*-----------------------------------------*/

.share-social-icons {
    display: inline-block;
    padding-left: 0;
}

.share-social-icons li {
    width: auto !important;
    display: inline-block !important;
    vertical-align: top;
    clear: none !important;
    padding: 0;
}

a.share-ico {
    width: 75px;
    height: 30px;
    font-size: 16px;
    line-height: 30px !important;
    padding-left: 13px;
    margin-right: 5px;
    display: block;
    opacity: 0.75;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

a.share-ico.ico-facebook {background-color: #3b5998; border-color: #3b5998; color: #fff;}
a.share-ico.ico-twitter {background-color: #00a9ed; border-color: #00a9ed; color: #fff;}
a.share-ico.ico-behance {background-color: #00a8e7; border-color: #00a8e7; color: #fff;}
a.share-ico.ico-google-plus {background-color: #cd1111; border-color: #cd1111; color: #fff;}
a.share-ico.ico-linkedin {background-color: #015886; border-color: #015886; color: #fff;}
a.share-ico.ico-dribbble{background-color: #d92d84; border-color: #d92d84; color: #fff;}
a.share-ico.ico-instagram {background-color: #beb3a8; border-color: #beb3a8; color: #fff;}
a.share-ico.ico-pinterest {background-color: #ac281a; border-color: #ac281a; color: #fff;}
a.share-ico.ico-dropbox {background-color: #008ad2; border-color: #008ad2; color: #fff;}
a.share-ico.ico-skype {background-color: #00a9ed; border-color: #00a9ed;color: #fff;}
a.share-ico.ico-youtube {background-color: #cd1b20; border-color: #cd1b20;color: #fff;}
a.share-ico.ico-tumblr {background-color: #3a5976; border-color: #3a5976;color: #fff;}
a.share-ico.ico-vimeo {background-color: #00adee; border-color: #00adee; color: #fff;}
a.share-ico.ico-flickr {background-color: #d2d2d2; border-color: #d2d2d2; color: #fff;}
a.share-ico.ico-github {background-color: #222; border-color: #222; color: #fff;}
a.share-ico.ico-renren {background-color: #364a83; border-color: #364a83; color: #fff;}
a.share-ico.ico-vk {background-color: #3b5998; border-color: #3b5998; color: #fff;}
a.share-ico.ico-xing {background-color: #015f5e; border-color: #015f5e; color: #fff;}
a.share-ico.ico-weibo {background-color: #be4443; border-color: #be4443; color: #fff;}
a.share-ico.ico-rss {background-color: #ff6600; border-color: #ff6600; color: #fff;}
a.share-ico.ico-digg {background-color: #222; border-color: #222; color: #fff;}
a.share-ico.ico-deviantart {background-color: #57675d; border-color: #57675d; color: #fff;}
a.share-ico.ico-envelope {background-color: #999; border-color: #999; color: #fff;}
a.share-ico.ico-delicious {background-color: #0A0A0A; border-color: #0A0A0A; color: #fff;}

a.share-ico:hover {
    opacity: 1;
}

/*-----------------------------------------*/
/*    RELATED POST
/*-----------------------------------------*/

.related-post {
    margin-top: 80px;
    margin-bottom: 80px;
    padding-top: 80px;
    padding-bottom: 40px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

/*------------------------------------------*/
/*    SINGLE POST META
/*------------------------------------------*/

.comment-meta {
    margin-bottom: 10px;
}

.comment-meta h5 {
    line-height: 1.1;
    margin-bottom: 2px;
}

.comment-date {
    color: #888;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.btn-reply a {
    background-color: #888;
    color:  #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 8px;
    text-transform: uppercase;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.btn-reply a:hover {
    color:  #fff;
    background-color: #2c353f;
}

.comment-reply {
    margin-left: 40px;
}

.single-post-comments hr {
    margin-top: 20px;
    margin-bottom: 20px;
}

/*------------------------------------------*/
/*    SINGLE POST COMMENT FORM
/*------------------------------------------*/

#leave-comment h5.h5-lg {
    margin-bottom: 3px;
}

.comment-form {
    position: relative;
}

.comment-form p {
    color: #333;
    font-size: 15px;
    line-height: 15px;
    font-weight: 700;
    margin-bottom: 10px;
    padding-left: 5px;
    display: block;
}

.comment-form .form-control {
    height: 52px;
    background-color: #fff;
    border: 2px solid #ccc;
    color: #333;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 20px;
    margin-bottom: 22px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

.comment-form .form-control:focus {
    border-color: #ff3366;
    outline: 0px none;
    box-shadow: none;
}

.comment-form textarea { min-height: 200px; }
.comment-form textarea.form-control {
    padding: 20px;
}

/*------------------------------------------*/
/*    Forms Message
/*------------------------------------------*/

.comment-form label.error {
    color: #f6412d;
    font-size: 0.95rem;
    line-height: 1;
    margin-bottom: 20px;
}

.comment-form-msg {
    position: absolute;
    bottom: 10px;
    left: 350px;
}

.comment-form-msg .error,
.comment-form-msg .loading {
    color: #45afff;
    font-size: 1.1rem;
    line-height: 1;
    font-weight: 600;
}

.comment-form-msg .error {
    color: #f6412d;
}




/* ==========================================================================
  17.  CONTACTS
  =========================================================================== */


#contacts-2 .section-title {
    margin-bottom: 15px;
}

#contacts-2 .form-holder {
    margin-right: 6%;
    margin-left: 6%;
}

.contacts-section .form-holder {
    position: relative;
}

/*------------------------------------------*/
/*    CONTACTS TYPOGRAPHY
/*------------------------------------------*/

.register-form p {
    color: #999;
    padding: 0 15%;
    margin-top: 20px;
    margin-bottom: 0;
}

.register-form p a {
    color: #666;
    text-decoration: underline;
}

.register-form p a:hover {
    color: #000;
}

/*------------------------------------------*/
/*    Contact Form Input
/*------------------------------------------*/

.contact-form .form-control,
.register-form .form-control {
    height: 56px;
    background-color: #fff;
    border: 1px solid #ccc;
    color: #444;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 20px;
    margin-bottom: 25px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.register-form .form-control {
    height: 58px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
}

.contact-form .custom-select {
    display: inline-block;
    width: 100%;
    height: 56px;
    line-height: 1;
    color: #444;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 15px;
    margin-bottom: 25px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.bg-lightgrey .contact-form .form-control {
    border: 1px solid #ddd;
}

/*------------------------------------------*/
/*    Contact Form Textarea
/*------------------------------------------*/

.contact-form textarea { min-height: 220px; }
.contact-form textarea.form-control {
    padding: 20px;
}

/*------------------------------------------*/
/*    Contact Form Placeholder
/*------------------------------------------*/

.contact-form .form-control::-moz-placeholder { color: #555; }
.contact-form .form-control:-ms-input-placeholder { color: #555; }
.contact-form .form-control::-webkit-input-placeholder { color: #555; }

/*------------------------------------------*/
/*    Contact Form Input Focus
/*------------------------------------------*/

.register-form .form-control:focus,
.contact-form .form-control:focus,
.contact-form .custom-select:focus {
    outline: 0 none;
    box-shadow: none;
    border-color: #48af4b;
}

/*------------------------------------------*/
/*    Register Form Button
/*------------------------------------------*/

.register-form .btn {
    width: 100%;
    height: 58px;
    font-weight: 600;
}

/*------------------------------------------*/
/*    Contact Form Message
/*------------------------------------------*/

.register-form label.error,
.contact-form label.error {
    color: #f6412d;
    font-size: 0.95rem;
    line-height: 1;
    margin-bottom: 20px;
    margin-left: 35px;
}

.register-form-msg,
.contact-form-msg {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.register-form-msg .error,
.contact-form-msg .error,
.register-form-msg .loading,
.contact-form-msg .loading {
    color: #45afff;
    font-size: 1.1rem;
    line-height: 1;
    font-weight: 600;
}

.register-form-msg .error,
.contact-form-msg .error {
    color: #f6412d;
}




/* ==========================================================================
  18. FOOTER
  ========================================================================== */

/*------------------------------------------*/
/*   FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h5 {
    margin-bottom: 25px;
}

.footer-socials-links h5 {
    letter-spacing: 0;
    margin-bottom: 13px;
}

.footer-socials-links h5 a {
    text-decoration: underline;
}

a.foo-facebook:hover { color: #3b5998; }
a.foo-twitter:hover { color: #00a9ed; }
a.foo-instagram:hover { color: #e44772 }
a.foo-dribbble:hover { color: #d92d84; }
a.foo-behance:hover { color: #2473f6; }
a.foo-pinterest:hover { color: #ac281a; }
a.foo-linkedin:hover { color: #015886; }
a.foo-google-plus:hover { color: #cd1111; }
a.foo-youtube:hover { color: #cd1b20; }
a.foo-tumblr:hover { color: #3a5976; }
a.foo-vk:hover { color: #3b5998; }

.bg-dark.footer .white-color h5 a:hover {
    color: #fff;
}

/*------------------------------------------*/
/*   FOOTER LINKS
/*------------------------------------------*/

.foo-links {
    display: inline-block;
    padding-left: 0;
    margin: 0 auto;
}

.foo-links li {
    width: auto !important;
    display: block !important;
    vertical-align: top;
    clear: none !important;
    margin: 0 0 8px 0;
    padding: 0;
}

#footer-3 .foo-links li,
#footer-5 .foo-links li,
.bottom-footer .foo-links li {
    display: inline-block !important;
    margin-left: 22px;
}

.foo-links li a {
    font-size: 1rem;
    line-height: 1.5;
}

#footer-3 .foo-links li a,
#footer-5 .foo-links li a,
.bottom-footer .foo-links li a {
    color: #666;
    font-size: 0.95rem;
    line-height: 1.5;
}

#footer-5 .foo-links li a {
    line-height: 35px!important;
}

.foo-links li a:hover {
    color: #000;
    text-decoration: underline;
}

.bg-dark .foo-links li a,
.bg-dark .foo-links li a:hover {
    color: #ddd;
}

/*------------------------------------------*/
/*   BOTTOM FOOTER
/*------------------------------------------*/

.bottom-footer {
    border-top: 1px solid #ddd;
    padding-top: 30px;
}

#footer-5 .bottom-footer {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
}

.bg-grey .bottom-footer {
    border-color: #ccc;
}

.bg-dark .bottom-footer {
    border-color: #555;
}

/*------------------------------------------*/
/*   FOOTER COPYRIGHT
/*------------------------------------------*/

.footer-copyright p {
    font-size: 0.95rem;
    line-height: 1.5;
    margin-bottom: 0;
}

#footer-2 .footer-copyright p {
    margin-bottom: 2px;
}

.footer-copyright p span {
    font-weight: 500;
}




/* ==========================================================================
  19. BOTTOM QUICK FORM
  ========================================================================== */

.bottom-form {
    background-color: #fff;
    min-width: 260px;
    max-width: 260px;
    box-shadow: 0 1px 15px 0 rgba(0,0,0,.33);
    position: fixed;
    right: 6em;
    bottom: 0;
    z-index: 998;
    outline: 0px none;
}

/*------------------------------------------*/
/*   QUICK FORM HEADER
/*------------------------------------------*/

.bottom-form-header {
    background-color: #333;
    border: none;
    padding: 14px 20px;
    min-height: 45px;
    cursor: pointer;
    outline: 0px none;
    position: relative;
}

.bottom-form-header span {
    text-align: center;
    position: absolute;
    right: 10px;
    top: -35px;
    width: 60px;
    height: 60px;
    color: #fff;
    line-height: 60px!important;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.bottom-form-header .svg-inline--fa {
    font-size: 1.85rem;
    line-height: 1!important;
}

.bottom-form-header p {
    color: #fff;
    font-size: 0.9rem;
    line-height: 30px!important;
    display: inline-block;
    margin-bottom: 0;
}

/*------------------------------------------*/
/*   QUICK FORM FORM
/*------------------------------------------*/

.bottom-form-holder {
    height: 311px;
    padding-top: 10px;
    padding-bottom: 20px;
    outline: 0px none;
}

.bottom-form-control {
    width: 100%;
    border: none;
    border-bottom: 1px solid #eee;
    font-size: 0.9rem;
    line-height: 1;
    padding: 18px;
}

.quick-contact-form textarea {
    min-height: 60px;
    border-bottom: none;
}

.quick-contact-form .btn {
    font-size: 0.825rem;
    padding: 0.72rem 1.5rem;
    letter-spacing: 0px;
    margin-right: 30px;
}

.quick-contact-form .btn-arrow .svg-inline--fa {
    top: 1px;
}

/*------------------------------------------*/
/*    Quick Form Message
/*------------------------------------------*/

.quick-contact-form label.error {
    color: #f6412d;
    font-size: 0.95rem;
    line-height: 1;
    margin-bottom: 20px;
    margin-left: 35px;
}

.quick-contact-form .contact-form-msg {
    position: absolute;
    bottom: 80px;
    left: 20px;
}

.quick-contact-form .contact-form-msg .error,
.quick-contact-form .contact-form-msg .loading {
    color: #45afff;
    font-size: 0.9rem;
    line-height: 1;
    font-weight: 300;
}

.quick-contact-form .contact-form-msg .error {
    color: #f6412d;
}




/* ==========================================================================
  20. TERMS PAGE
  ========================================================================== */

#terms-page {
    margin-top: 180px;
    margin-bottom: 45px;
}

/*------------------------------------------*/
/*   TERMS PAGE TYPOGRAPHY
/*------------------------------------------*/

#terms-page p.p-notice {
    font-weight: 700;
}

.terms-box {
    margin-bottom: 40px;
}

.terms-box h4 {
    font-weight: 600;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
}

.terms-box p span {
    font-size: 1.1rem;
    line-height: 1;
    font-weight: 700;
    margin-right: 5px;
}

.terms-box p a {
    color: #333;
    font-weight: 700;
    text-decoration: underline;
}

.terms-box p a:hover {
    color: #999;
}

/*------------------------------------------*/
/*    Terms / Privacy Title
/*------------------------------------------*/

ul.terms-list {
    list-style: disc;
    margin: 20px 0 20px 25px;
}

ul.terms-list li {
    margin-bottom: 10px;
}

ul.terms-list li:last-child {
    margin-bottom: 0;
}




/* ==========================================================================
  21.  SCROLL TO TOP
  =========================================================================== */

#scrollUp {
    display: none;
    width: 40px;
    height: 45px;
    position: fixed;
    bottom: 20px;
    right: 40px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-image: url(../../assets/img/back-to-top.png);
    background-repeat: no-repeat;
    background-position: 50% 48%;
    background-color: rgba(35, 35, 35, 0.65);
    -webkit-transition: all 250ms linear;
    -moz-transition: all 250ms linear;
    transition: all 250ms linear;
}

#scrollUp:hover {
    background-color: #151515;
}

nav a#pull {
    display: none;
}
#header_nav{
    transition: background-color 0.4s linear, padding 0.4s linear;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1030;
}
.container_nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*margin: 40px 300px;*/
}
.default_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.logo_navbar{
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 600;
}
.ul_navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.li_navbar{
    margin: 0 10px;
}
.link_navbar{
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 16px;
    text-transform: uppercase;
}
.li_navbar_what_we_do{
    text-align: center;
    padding: 8px 0;
    position: absolute;
    background-color: white;
    border-radius: 6px;
}
.dropdown_item_navbar_li:hover{
    background-color: #eeeeee;
}
.dropdown_item_navbar_li{
    transition: all 0.3s ease;
}
.dropdown_item_navbar{
    white-space: nowrap;
    color: #636363;
    margin: 4px 16px;
    border-radius: 6px;
    font-size: 18px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
.home_btn_navbar{
    background-color: black;
    padding: 12px 26px;
    border-radius: 6px;
}
.login_btn_navbar{
    background-color: #28912d;
    padding: 12px 26px;
    border-radius: 6px;
}
.burger_menu_container{
    display: block;

}
#nav-icon1 {
    width: 40px;
    height: 30px;
    position: relative;
    /*margin: 15px auto;*/
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}
#nav-icon1 span {
    display: block;
    position: absolute;
    margin-bottom: 7px;
    height: 5px;
    width: 100%;
    background: #ffffff;
    background: #ffffff;
    border-radius: 6px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}
#nav-icon1 span:nth-child(1) {
    top: 0px;
}
#nav-icon1 span:nth-child(2) {
    top: 11px;
}
#nav-icon1 span:nth-child(3) {
    top: 22px;
}
#nav-icon1.open span:nth-child(1) {
    top: 18px;
    transform: rotate(135deg);
}
#nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}
.custom_changeLang_btn{
    background-color:transparent!important;
    box-shadow:none!important;
    outline:none!important;
    margin-right:20px!important;
    border:none!important;
    font-weight:bold!important;
    font-size:18px!important;
}
@media (max-width: 768px) {
    .custom-text-align{
        text-align:center;
    }
    .custom-reverse{
        flex-direction: column-reverse;
    }
    .custom-margin_welcome_page{
        margin-top: 30px!important;
    }
}
#nav-icon1.open span:nth-child(3) {
    top: 18px;
    transform: rotate(-135deg);
}
.burger_menu_dropdown{
    top: 80px;
    position: fixed;
    z-index: 1030;
    width: 100%;
    background-color: #333;
}
.burger_menu_dropdown_ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
.burger_menu_dropdown_li{
    margin: 12px;
}
@media (min-width: 1600px){
    #header_nav{
        padding-right: 150px!important;
        padding-left: 300px!important;
    }
}
@media (max-width: 1256px){
    .link_navbar{
        font-size: 14px!important;
    }
}
@media (max-width: 1256px){
    .link_navbar{
        font-size: 14px!important;
    }
}
@media (max-width: 1014px){
    .link_navbar{
        font-size: 13px!important;
    }
}
@media (min-width: 1200px) and (max-width: 1599.9px){
    #header_nav{
        padding-right: 100px!important;
        padding-left: 100px!important;
    }

}
@media (min-width: 992px) and (max-width: 1199.9px){
    #header_nav{
        padding-right: 50px!important;
        padding-left: 50px!important;
    }
}
@media (min-width: 635px) and (max-width: 991px){
    #header_nav{
        padding-right: 100px!important;
        padding-left: 100px!important;
        background-color: #333!important
    }
}
@media (max-width: 634px){
    #header_nav{
        padding-right: 30px!important;
        padding-left: 30px!important;
        background-color: #333!important
    }
}


@media (max-width: 992px){
    .default_container{
        display: none;
    }
    .burger_menu_container{
        display: block;
    }

}
@media (min-width: 992px){
    .default_container{
        display: flex;
    }
    .burger_menu_container{
        display: none;
    }
}

.input_group{
    align-items: center;
}

#s-email.form-control.email_button_edit{
    padding: 30px!important;
    border-radius: 6px 6px 0 0!important;
}